import { Box, BoxProps, Button } from "@chakra-ui/react";
import React from "react";

import { LoadingIndicator } from "../../../components/LoadingIndicator";
import { CandidateAlertFeedListItemFragment } from "../../graphql";
import { CandidateAlert } from "./CandidateAlert";
import NoCandidateAlerts from "./NoCandidateAlerts";

export const CandidateAlertList: React.FC<{
  alerts: CandidateAlertFeedListItemFragment[];
  loading: boolean;
  showLoadMore: boolean;
  onLoadMore: () => void;
  handleAlertRead: (ids: string[]) => void;
  noAlertHelperText?: string;
  itemStyles?: BoxProps;
}> = ({
  alerts,
  loading,
  showLoadMore,
  onLoadMore,
  handleAlertRead,
  noAlertHelperText,
  itemStyles,
}) => {
  if (alerts.length === 0 && !loading) {
    return <NoCandidateAlerts helperText={noAlertHelperText} />;
  }
  return (
    <Box>
      {loading && <LoadingIndicator delay={0} py="2" />}
      {!loading && (
        <>
          {alerts.map((alertItem) => {
            return (
              <CandidateAlert
                key={alertItem.id}
                handleAlertRead={handleAlertRead}
                styles={itemStyles}
                {...alertItem}
              />
            );
          })}
          {showLoadMore && (
            <Button
              variant="ghost"
              colorScheme="gray"
              size="sm"
              fontWeight="medium"
              marginTop="2"
              marginLeft="2"
              onClick={onLoadMore}
            >
              Load more
            </Button>
          )}
        </>
      )}
    </Box>
  );
};
