import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  IconButtonProps,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import {
  HiOutlineThumbDown,
  HiOutlineThumbUp,
  HiThumbDown,
  HiThumbUp,
  HiX,
} from "react-icons/hi";
import ResizeTextarea from "react-textarea-autosize";

import { useSendGAEvent } from "../../../../../../../utils/googleAnalytics";
import { useIsExtension } from "../../../../../../hooks/useAppEnvironmentContext";

const AiFeedback: React.FC<{
  feature: string;
  /** Will expose a text box for additional feedback after choosing a thumbs up / thumbs down */
  allowText?: boolean;
}> = ({ feature, allowText }) => {
  const [submitted, setSubmitted] = useState<string | null>(null);
  const textBox = useDisclosure();
  const [textIsSubmitted, setTextIsSubmitted] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const sendGAEvent = useSendGAEvent();
  /**
   * Using `isExtension` instead of responsive styling because we have to dodge
   * the intercom launcher in the extension, but not in the main app
   */
  const isExtension = useIsExtension();
  const toast = useToast();
  const successToast = (msg: string): void => {
    toast({
      description: msg,
      status: "success",
      position: isExtension ? "top" : "bottom",
      duration: 2000,
    });
  };

  const rateNotes = useCallback(
    (valence: "positive" | "negative"): void => {
      if (!submitted) {
        sendGAEvent(`rate_ai_${feature}`, "user_feedback", valence);
        setSubmitted(valence);
        if (allowText) {
          textBox.onOpen();
        }
        successToast("Thank you for your feedback!");
      }
    },
    [submitted]
  );

  const iconProps = {
    size: "24px",
  };

  const parent = document.getElementById("ai-notes-feedback");
  const sidebar = document.getElementById("call-sidebar");
  if (!(sidebar && parent?.parentElement)) {
    return null;
  }
  parent.style.position = "fixed";
  parent.style.bottom = "0";
  parent.style.width = `${sidebar.clientWidth}px`;
  parent.style.right = "0";
  const textToDisplay = (): string => {
    let featureArray = feature.split("-");
    if (featureArray[0] === "topic") {
      featureArray = featureArray.splice(1).map((str) => str.replace("_", " "));
    } else if (["notes", "scorecard"].includes(featureArray[0])) {
      return "";
    }
    return featureArray.join(" ");
  };

  return createPortal(
    <Flex
      direction="column"
      align="center"
      bg="white"
      borderColor="gray.200"
      borderTopWidth="1px"
      py="2"
      px="4"
    >
      <Flex
        align="center"
        justify="center"
        gap={isExtension ? 2 : 4}
        mr={isExtension ? 50 : undefined}
      >
        <Text color="gray.600" fontWeight="600" fontSize="sm">
          {`Are these AI-generated ${textToDisplay()} notes helpful?`}
        </Text>
        <Flex direction="row">
          <RatingButton
            aria-label="Thumbs up"
            icon={
              submitted === "positive" ? (
                <HiThumbUp {...iconProps} />
              ) : (
                <HiOutlineThumbUp {...iconProps} />
              )
            }
            onClick={() => {
              rateNotes("positive");
            }}
            isDisabled={!!submitted}
            data-tour-id={`ai-notes-thumbsup-${feature.replace("_", "-")}`}
          />
          <RatingButton
            aria-label="Thumbs down"
            icon={
              submitted === "negative" ? (
                <HiThumbDown {...iconProps} />
              ) : (
                <HiOutlineThumbDown {...iconProps} />
              )
            }
            onClick={() => {
              rateNotes("negative");
            }}
            isDisabled={!!submitted}
            data-tour-id={`ai-notes-thumbsdown-${feature.replace("_", "-")}`}
          />
        </Flex>
      </Flex>
      {/* text box for additional feedback */}
      <AnimatePresence>
        {textBox.isOpen && (
          <Box
            as={motion.div}
            position="relative"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1, transition: { delay: 0.2 } }}
            exit={{ height: 0, opacity: 0 }}
            maxW="550px"
            w="100%"
          >
            <Textarea
              as={ResizeTextarea}
              onChange={(e) => setFeedbackText(e.target.value)}
              maxRows={10}
              placeholder="Comment on your feedback (optional)"
              minH="7em"
              fontSize="sm"
              resize="none"
              mb="1"
              pr={isExtension ? "56px" : "86px"}
              pb={isExtension ? "36px" : undefined}
            />
            <IconButton
              position="absolute"
              top="1.5"
              right="2.5"
              zIndex="1"
              variant="ghost"
              aria-label="Cancel"
              color="gray.600"
              colorScheme="gray"
              icon={<Icon as={HiX} boxSize="18px" />}
              boxSize="5"
              minW="unset"
              onClick={textBox.onClose}
            />
            <Button
              position="absolute"
              bottom="3"
              right={isExtension ? undefined : "2.5"}
              left={isExtension ? "2.5" : undefined}
              zIndex="1"
              variant="outline"
              size="xs"
              minW="unset"
              opacity={feedbackText ? 1 : 0}
              disabled={textIsSubmitted}
              onClick={() => {
                if (textIsSubmitted || !feedbackText) return;
                sendGAEvent(
                  `rate_ai_${feature}_text`,
                  "user_feedback",
                  feedbackText
                );
                setTextIsSubmitted(true);
                setTimeout(textBox.onClose, 1_500);
                successToast("Feedback submitted");
              }}
            >
              Submit
            </Button>
          </Box>
        )}
      </AnimatePresence>
    </Flex>,
    parent
  );
};

const RatingButton: React.FC<IconButtonProps> = (props) => (
  <IconButton
    variant="unstyled"
    color="blue.600"
    align="center"
    justify="center"
    display="flex"
    size="lg"
    _hover={{ bg: "transparent" }}
    _active={{ bg: "transparent" }}
    _disabled={{ color: "blue.600" }}
    {...props}
  />
);

export default AiFeedback;
