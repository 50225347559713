import React from "react";
import { useParams } from "react-router-dom";

import { canViewOrgInsights } from "../../../utils/permissions";
import { MetricName } from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import useCurrentUser from "../../hooks/useCurrentUser";
import AnalyticsMetricsContent from "./AnalyticsMetricsContent";
import useAnalyticsConfig from "./useAnalyticsConfig";
import useAnalyticsData from "./useAnalyticsData";

const AnalyticsMetrics: React.FC = () => {
  const { metric: metricParam } = useParams<{ metric: string }>();
  const metric = metricParam?.toUpperCase() as MetricName;
  const analyticsConfig = useAnalyticsConfig(metric);

  const hcaMetricsEnabled = useFeatureFlag("analytics:hca-metrics");
  const deiMetricsDisabled = useFeatureFlag("analytics:disable-dei");
  const statusSegmentationEnabled = useFeatureFlag(
    "analytics:status-segmentation"
  );

  const currentUser = useCurrentUser();
  const canViewMetrics = canViewOrgInsights(currentUser);
  const analyticsData = useAnalyticsData(analyticsConfig, {
    skipAllQueries: !canViewMetrics,
  });

  return (
    <AnalyticsMetricsContent
      analyticsConfig={analyticsConfig}
      analyticsData={analyticsData}
      flaggedFeatures={{
        genderSegmentation: !deiMetricsDisabled,
        statusSegmentation: statusSegmentationEnabled,
        togglePerformanceOverlay: hcaMetricsEnabled,
        drilldowns: true,
      }}
    />
  );
};

export default AnalyticsMetrics;
