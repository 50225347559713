import { callTimestampedLink } from "../../CallNotes/utils";
import { Topic } from "./types";

export const encodeToAnchor = (typePrefix: string, str: string): string => {
  const unescapedAnchor = `${typePrefix}-${str}`;
  return encodeURIComponent(unescapedAnchor.trim().replace(/\s+/g, "-"));
};

export const formatTopic = (
  topic: Topic,
  citationMap: { [key: string]: number }
): { plain: string; html: string } => {
  let plain = "";
  let html = "";

  const subtopics = topic.subTopics;

  plain += `${topic.name}\n`;
  html += `<h2>${topic.name}</h2>`;
  subtopics.forEach((subtopic) => {
    plain += `\n${subtopic.name}\n\n`;
    html += `<h3>${subtopic.name}</h3>`;
    subtopic.notes.forEach((note) => {
      if (note.text !== "") {
        plain += `${note.text}\n`;
        html += `${note.text} `;
        const citationLine = note.citations
          .map((citation) => {
            return `[<a href="${callTimestampedLink(
              citation.callId,
              citation.time
            )}">${citationMap[citation.id].toString()}</a>]`;
          })
          .join(" ");
        html += `${citationLine}<br>`;
      } else {
        plain += "(No results found for this subtopic.)\n";
        html += "<i>(No results found for this subtopic.)</i><br>";
      }
    });
  });

  return { plain, html };
};
