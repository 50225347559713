import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import MultiValueSelect from "../../../components/MultiValueSelect/MultiValueSelect";
import {
  formatDateDurationLine,
  monthDisplayMap,
} from "../../../utils/datetime";
import {
  PrimaryCalendarEventsQuery,
  useCreateEventImportKeywordMutation,
  useCurrentUserEventImportKeywordsQuery,
  useDeleteEventImportKeywordMutation,
  usePrimaryCalendarEventsQuery,
} from "../../graphql";

type Event = PrimaryCalendarEventsQuery["primaryCalendarEvents"][0];
type EventListItem = {
  month: number;
  days: {
    day: number;
    dayOfWeek: string;
    events: Event[];
  }[];
};

const getDayAbbreviation = (date: Date): string => {
  const options: Intl.DateTimeFormatOptions = { weekday: "short" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const buildEventList = (calendarEvents: Event[]): EventListItem[] => {
  const eventList: EventListItem[] = [];
  let prevMonth: number;

  calendarEvents.forEach((item) => {
    const startDate = new Date(item.start);
    const startMonth = startDate.getMonth();
    const startDay = startDate.getDate();
    // Group by months
    if (startMonth !== prevMonth) {
      eventList.push({
        month: startMonth,
        days: [
          {
            day: startDay,
            dayOfWeek: getDayAbbreviation(startDate),
            events: [item],
          },
        ],
      });
      prevMonth = startMonth;
    } else {
      // Group by days
      const lastEvent = eventList[eventList.length - 1];
      const lastDay = lastEvent.days[lastEvent.days.length - 1];
      if (lastDay.day !== startDay) {
        lastEvent.days.push({
          day: startDay,
          dayOfWeek: getDayAbbreviation(startDate),
          events: [item],
        });
      } else {
        lastDay.events.push(item);
      }
    }
  });

  return eventList;
};

const EventImportKeyword: React.FC = () => {
  const [keywords, setKeywords] = useState<string[]>([]);
  const [eventList, setEventList] = useState<EventListItem[]>([]);
  const { data } = useCurrentUserEventImportKeywordsQuery();
  const { data: events } = usePrimaryCalendarEventsQuery();

  useEffect(() => {
    const savedKeywords = data?.currentUser?.eventImportKeywords;
    if (savedKeywords) {
      setKeywords(savedKeywords.map((item) => item.keywordText));
    }
  }, [data]);
  useEffect(() => {
    if (events?.primaryCalendarEvents) {
      setEventList(buildEventList(events?.primaryCalendarEvents));
    }
  }, [events]);

  const addDeleteFromEventList = (
    addedKeywords: string[],
    deletedKeywords: string[],
    currentEventList: EventListItem[]
  ): EventListItem[] => {
    return currentEventList.map((item) => {
      return {
        ...item,
        days: item.days.map((dayItem) => {
          return {
            ...dayItem,
            events: dayItem.events.map((event) => {
              // Removes the need to check excluded
              if (event.importExcludeDecision === false) {
                return { ...event };
              }
              const deletedFound = deletedKeywords.find((keyword) => {
                if (
                  event.summary
                    ?.toLowerCase()
                    .includes(keyword.trim().toLowerCase())
                ) {
                  return true;
                }
                return false;
              });
              const addedFound = addedKeywords.find((keyword) => {
                if (
                  event.summary
                    ?.toLowerCase()
                    .includes(keyword.trim().toLowerCase())
                ) {
                  return true;
                }
                return false;
              });
              return {
                ...event,
                importExcludeDecision: deletedFound // set to nothing if deleted keyword is found
                  ? undefined
                  : addedFound || event.importExcludeDecision === true // if keyword is not found, then leave the state as is
                  ? true
                  : undefined,
              };
            }),
          };
        }),
      };
    });
  };

  const [createKeyword] = useCreateEventImportKeywordMutation();
  const addKeyword = (newKeyword: string): void => {
    if (!keywords.includes(newKeyword)) {
      setKeywords((prevKeywords) => [...prevKeywords, newKeyword]);
      createKeyword({ variables: { keywords: [newKeyword] } });
      setEventList((prevList) => {
        return addDeleteFromEventList([newKeyword], [], prevList);
      });
    }
  };

  const [deleteKeywords] = useDeleteEventImportKeywordMutation();
  const handleValueChange = (anyValue: string[]): void => {
    const deletedKeywords = keywords.filter((item) => !anyValue.includes(item));
    if (deletedKeywords.length > 0) {
      setKeywords(anyValue);
      deleteKeywords({ variables: { keywords: deletedKeywords } });
      setEventList((prevList) => {
        return addDeleteFromEventList([], deletedKeywords, prevList);
      });
    }
  };

  return (
    <Flex dir="row" gap={3}>
      <Box>
        <Box textColor="gray.600" fontWeight="600" fontSize="sm" mb="2">
          Keywords or phrases that identify interviews
        </Box>
        <Box
          py="2"
          px="3"
          backgroundColor="gray.50"
          height="272px"
          minH="272px"
          width="319px"
          maxW="319px"
          borderRadius="8px"
        >
          <Text fontSize="sm" fontWeight="400" mb="1">
            Be specific in choosing your keywords and phrases as BrightHire will
            join all events that contain any of them:
          </Text>
          <MultiValueSelect
            fontSize="sm"
            autoFocus
            value={keywords}
            onAddValue={(keyword) => addKeyword(keyword)}
            onValueChange={handleValueChange}
            getOptionLabel={(s) => s}
            getOptionValue={(s) => s}
            addValueOnBlur
            placeholder="Enter keywords or phrases"
            helpTextComponent={
              <Text textColor="gray.500" fontWeight="500" fontSize="xs" mt="1">
                Separate keywords with a comma or enter key.
              </Text>
            }
          />
        </Box>
      </Box>
      <Box>
        <Box textColor="gray.600" fontWeight="600" fontSize="sm" mb="2">
          Events BrightHire will join
        </Box>
        <Box
          py="2"
          px="3"
          border="1px"
          borderColor="gray.200"
          borderRadius="8px"
          height="272px"
          minH="272px"
          maxH="272px"
          overflowY="scroll"
          width="319px"
          maxW="319px"
        >
          {eventList.map((item) => (
            <Box key={item.month}>
              <Box
                borderRadius="4px"
                background="gray.50"
                textAlign="center"
                textColor="gray.800"
                fontSize="xs"
                fontWeight="500"
                py="2px"
              >
                {monthDisplayMap[item.month]}
              </Box>
              <Box>
                {item.days.map((dayItem) => (
                  <Flex dir="row" gap="3" mt="2" key={dayItem.day}>
                    <Box>
                      <Box
                        fontSize="xx-small"
                        textAlign="center"
                        textColor="gray.600"
                      >
                        {dayItem.dayOfWeek}
                      </Box>
                      <Box
                        textAlign="center"
                        textColor="gray.800"
                        fontSize="lg"
                        width="32px"
                        minW="32px"
                        h="28px"
                        maxH="28px"
                      >
                        {dayItem.day}
                      </Box>
                    </Box>
                    <Box width="100%">
                      {dayItem.events.map((eventItem) => (
                        <Tooltip
                          label={
                            eventItem.importExcludeDecision === true
                              ? "Event is set to be imported. Please note that only events with video conference links will be able to be captured."
                              : eventItem.importExcludeDecision === false
                              ? "Organization wide import rules are preventing this event from being captured by BrightHire."
                              : "This event can be imported."
                          }
                          key={eventItem.id}
                        >
                          <Box
                            py="1"
                            px="2"
                            border="1px"
                            borderColor={
                              eventItem.importExcludeDecision === true
                                ? "blue.500"
                                : eventItem.importExcludeDecision === false
                                ? "red.500"
                                : "gray.200"
                            }
                            backgroundColor={
                              eventItem.importExcludeDecision === true
                                ? "blue.50"
                                : eventItem.importExcludeDecision === false
                                ? "red.50"
                                : "white"
                            }
                            width="100%"
                            textColor="gray.800"
                            mb="1"
                          >
                            <Box fontSize="xs" fontWeight="600">
                              {eventItem.summary}
                            </Box>
                            <Box fontSize="xs" fontWeight="400">
                              {formatDateDurationLine(
                                eventItem.start,
                                eventItem.end
                              )}
                            </Box>
                          </Box>
                        </Tooltip>
                      ))}
                    </Box>
                  </Flex>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Flex>
  );
};

export default EventImportKeyword;
