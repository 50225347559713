import { Box, ButtonGroup, Flex, IconButton, Tooltip } from "@chakra-ui/react";
import React, { useState } from "react";
import { HiThumbDown, HiThumbUp } from "react-icons/hi";

const FeedbackMenu: React.FC<{
  savePositiveFeedback: () => void;
  saveNegativeFeedback: () => void;
}> = ({ savePositiveFeedback, saveNegativeFeedback }) => {
  const [feedbackClicked, setFeedbackClicked] = useState(false);
  return (
    <Box gridArea="menu" position="relative">
      <Flex alignItems="center" position="absolute" right="0" bottom="-4px">
        <ButtonGroup
          size="sm"
          spacing="0"
          bg="white"
          borderColor="border"
          borderWidth="1px"
          borderRadius="md"
          variant="ghost"
          colorScheme="gray"
          p="1px"
        >
          {feedbackClicked ? (
            <Box m="1.5">Thank you for your feedback</Box>
          ) : (
            <>
              <Tooltip label="Accurate">
                <IconButton
                  aria-label="Accurate note"
                  icon={<HiThumbUp />}
                  onClick={() => {
                    savePositiveFeedback();
                    setFeedbackClicked(true);
                  }}
                />
              </Tooltip>
              <Tooltip label="Inaccurate">
                <IconButton
                  aria-label="Inaccurate note"
                  icon={<HiThumbDown />}
                  onClick={() => {
                    saveNegativeFeedback();
                    setFeedbackClicked(true);
                  }}
                />
              </Tooltip>
            </>
          )}
        </ButtonGroup>
      </Flex>
    </Box>
  );
};

export default FeedbackMenu;
