import { useUserCandidateSummaryVisibilityQuery } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";

export const useShowCandidateSummary = (): boolean => {
  const featureEnabled = useFeatureFlag("candidate_summary:ui");
  const { data: visible } = useUserCandidateSummaryVisibilityQuery();
  if (featureEnabled && visible?.currentUser?.canViewCandidateSummaries) {
    return true;
  }
  return false;
};
