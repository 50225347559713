import { useUserCandidateSummaryVisibilityQuery } from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";

export const useShowEditCandidateSummaryTemplate = (): boolean => {
  const featureEnabled = useFeatureFlag("candidate_summary:ui");
  const editFeatureEnabled = useFeatureFlag("candidate_summary:edit");
  const { data: visible } = useUserCandidateSummaryVisibilityQuery();
  if (
    featureEnabled &&
    editFeatureEnabled &&
    visible?.currentUser?.canEditCandidateSummaryTemplate
  ) {
    return true;
  }
  return false;
};
