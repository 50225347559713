import { Button, Flex, Tooltip } from "@chakra-ui/react";
import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";

import { useTheme } from "../../../../components";
import { AtsIcon } from "../../../../components/Icons/AtsIcon";

type DisplayLink = {
  href: string;
  tooltipLabel: string;
};

export type ATSLinks = {
  greenhouse?: DisplayLink;
  lever?: DisplayLink;
  linkedIn?: DisplayLink;
  smartRecruiters?: DisplayLink;
  ashby?: DisplayLink;
  custom?: DisplayLink;
};

const LinkButton: React.FC<{
  link: DisplayLink;
  label: string;
  testId?: string;
  icon: React.ReactElement;
}> = ({ link: { href, tooltipLabel }, label, testId, icon }) =>
  href ? (
    <Tooltip label={tooltipLabel} openDelay={300}>
      <Button
        as="a"
        href={href}
        target="_blank"
        data-testid={testId}
        aria-label={label}
        size="xs"
        variant="chalk"
        color="blue.600"
        leftIcon={icon}
        iconSpacing="0"
        p="0"
        mr="1"
      />
    </Tooltip>
  ) : null;

const CandidateLinks: React.FC<ATSLinks> = ({
  ashby,
  greenhouse,
  lever,
  linkedIn,
  smartRecruiters,
  custom,
}) => {
  const { colors } = useTheme();
  const iconProps = {
    marginRight: "0",
    fill: colors.blue[600],
  };

  let link = null;
  let atsName = null;
  if (greenhouse?.href) {
    link = greenhouse;
    atsName = "Greenhouse";
  } else if (lever?.href) {
    link = lever;
    atsName = "Lever";
  } else if (smartRecruiters?.href) {
    link = smartRecruiters;
    atsName = "SmartRecruiters";
  } else if (ashby?.href) {
    link = ashby;
    atsName = "Ashby";
  }

  return (
    <Flex color="blue.600">
      {!!link && !!atsName && (
        <LinkButton
          link={link}
          label={atsName}
          testId=""
          icon={
            <AtsIcon
              {...iconProps}
              isGreenhouse={atsName === "Greenhouse"}
              isLever={atsName === "Lever"}
              isAshby={atsName === "Ashby"}
              isSmartrecruiters={atsName === "SmartRecruiters"}
            />
          }
        />
      )}
      {linkedIn?.href && (
        <LinkButton
          link={linkedIn}
          label="LinkedIn"
          testId=""
          icon={<FaLinkedin size="20" />}
        />
      )}
      {custom?.href && (
        <LinkButton
          link={custom}
          label="ATS"
          testId=""
          icon={<FiExternalLink size="19" />}
        />
      )}
    </Flex>
  );
};

export default CandidateLinks;
