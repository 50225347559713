import { formatISODate } from "../../../../utils/datetime";
import {
  AnalyticsBenchmarksQuery,
  AnalyticsReportMyInsightsQuery,
  ReportDataPoint,
  useAnalyticsBenchmarksQuery,
  useAnalyticsReportMyInsightsQuery,
} from "../../../graphql";
import useFeatureFlag from "../../../graphql/hooks/useFeatureFlag";
import { MyAnalyticsConfig } from "./useMyAnalyticsConfig";

export type MyInsightsData = {
  baseData: ReportDataPoint[];
  comparisonData: ReportDataPoint[];
  columnsConfig: AnalyticsReportMyInsightsQuery["qualityReportTrends"]["config"];
  loading: boolean;
  error?: any;
  bucketSize: number;
  bucketInterval: string;
  benchmarks: AnalyticsBenchmarksQuery["benchmarks"]["data"];
  totalCalls: number;
};

/**
 * Query results and any error states related to My Insights report.
 */
const useMyInsightsData = (
  queryConfig: MyAnalyticsConfig,
  interviewerEmail?: string
): MyInsightsData => {
  const myInsightsEnabled = useFeatureFlag("analytics:my-insights");

  const dataQuery = useAnalyticsReportMyInsightsQuery({
    variables: {
      dateRangeStart: formatISODate(queryConfig.dateRange.value.start),
      dateRangeEnd: formatISODate(queryConfig.dateRange.value.end),
      ...queryConfig.filters,
      positions: [],
      departments: [],
      stages: [],
      includeRawResults: true,
    },
    skip: !myInsightsEnabled || !interviewerEmail,
  });

  const baseData = dataQuery.data?.qualityReportTrends?.data || [];
  const comparisonData =
    dataQuery.data?.qualityReportTrends?.comparisonData || [];
  const columnsConfig = dataQuery.data?.qualityReportTrends?.config || [];
  const { bucketSize, bucketInterval } =
    dataQuery.data?.qualityReportTrends || {};

  // benchmarks
  const analyticsBenchmarksQuery = useAnalyticsBenchmarksQuery();
  const benchmarks = analyticsBenchmarksQuery.data?.benchmarks?.data || [];

  return {
    baseData,
    comparisonData,
    columnsConfig,
    loading: dataQuery.loading,
    error: dataQuery.error,
    bucketSize: bucketSize || 0,
    bucketInterval: bucketInterval || "",
    benchmarks,
    totalCalls: baseData.reduce(
      (acc, dataPoint) => acc + (dataPoint.totalCalls || 0),
      0
    ),
  };
};

export default useMyInsightsData;
