import {
  Button,
  ButtonProps,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuListProps,
  MenuProps,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { FaCircle } from "react-icons/fa";
import { GoTriangleDown } from "react-icons/go";
import { HiOutlineBell } from "react-icons/hi2";

import {
  CandidateAlertListItemFragment,
  CandidateAlertType,
} from "../../../graphql";
import { CandidateAlertCandidateList } from "../../CandidateAlert/CandidateAlertCandidateList";

type CandidateAlertButtonProps = {
  alerts: CandidateAlertListItemFragment[];
  loading: boolean;
  showLoadMore?: boolean;
  menuProps?: Omit<MenuProps, "children">;
  menuListProps?: MenuListProps;
  buttonProps?: ButtonProps;
  onShowMenu?(): void;
  onLoadMore?(): void;
};

export const CandidateAlertButton: React.FC<CandidateAlertButtonProps> = ({
  alerts,
  loading,
  showLoadMore = false,
  menuProps,
  menuListProps,
  buttonProps,
  onShowMenu,
  onLoadMore,
}) => {
  const tooltip = useDisclosure();

  if (loading) return null;

  if (alerts.length === 0) {
    return (
      <Tooltip label="No alerts">
        <IconButton
          aria-label="Show alerts"
          icon={<Icon as={HiOutlineBell} boxSize="6" />}
          p="2"
          variant="icon"
          disabled
        />
      </Tooltip>
    );
  }

  const hasClosingRisks = alerts.some(
    ({ type }) => type === CandidateAlertType.ClosingRisk
  );

  return (
    <Menu
      autoSelect={false}
      placement="bottom-end"
      onOpen={tooltip.onClose}
      {...menuProps}
    >
      {hasClosingRisks && (
        <MenuButton
          aria-label="Show alerts"
          variant="outline"
          backgroundColor="red.50"
          borderColor="red.200"
          fontWeight="500"
          color="red.600"
          as={Button}
          px="2"
          h="8"
          fontSize="14px"
          leftIcon={<Icon as={HiOutlineBell} boxSize="18px" />}
          iconSpacing="1"
          rightIcon={<GoTriangleDown />}
          flexShrink="0"
          onClick={onShowMenu}
          _hover={{ bg: "red.200" }}
          _focus={{ color: "red.600", bg: "red.100" }}
          _active={{ color: "red.600", bg: "red.100" }}
          {...buttonProps}
        >
          Closing risk ({alerts.length < 10 ? alerts.length : "9+"})
        </MenuButton>
      )}
      {!hasClosingRisks && (
        <Tooltip label="Show alerts" isOpen={tooltip.isOpen}>
          <MenuButton
            aria-label="Show alerts"
            as={Button}
            variant="icon"
            pos="relative"
            leftIcon={<Icon as={HiOutlineBell} boxSize="6" />}
            iconSpacing="0"
            p="2"
            onClick={onShowMenu}
            onMouseEnter={tooltip.onOpen}
            onMouseLeave={tooltip.onClose}
            _focus={{ bg: "inherit" }}
            _active={{ bg: "inherit" }}
            {...buttonProps}
          >
            <Icon
              as={FaCircle}
              pos="absolute"
              color="red.500"
              right="2.5"
              top="2.5"
              boxSize="2.5"
              p="0.5"
              bg="white"
              borderRadius="100%"
            />
          </MenuButton>
        </Tooltip>
      )}
      <MenuList
        maxW="418px"
        mx="2"
        maxH="min(900px, 95vh)"
        overflowY="auto"
        {...menuListProps}
      >
        <CandidateAlertCandidateList
          alerts={alerts}
          onLoadMore={() => onLoadMore?.()}
          loading={loading}
          showLoadMore={showLoadMore}
        />
      </MenuList>
    </Menu>
  );
};
