import {
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineBriefcase } from "react-icons/hi";
import { IoPeopleSharp } from "react-icons/io5";
import { MdMoreVert } from "react-icons/md";

import useIsOverflowing from "../../../../hooks/useIsOverflowing";
import { useIsSmallScreen } from "../../../../hooks/useIsSmallScreen";
import { formatDate } from "../../../../utils/datetime";
import { ExternalUser, User } from "../../../graphql";
import { CallViewers } from "../../Interview";
import MobileDetailsHeader, {
  MobileDetailsHeaderProps,
} from "./MobileDetailsHeader";

type EditableField = "candidate" | "position" | "name";

export type DetailsHeaderProps = MobileDetailsHeaderProps & {
  viewers?: Pick<
    User,
    "id" | "fullName" | "firstName" | "lastName" | "profilePicUrl" | "email"
  >[];
  externalViewers?: Pick<ExternalUser, "id" | "email">[];
  share?: JSX.Element;
  menuOptions?: JSX.Element[];
  onEdit?(field: EditableField): void;
  canEdit: boolean;
};

const DetailsHeader: React.FC<DetailsHeaderProps> = ({
  candidate,
  position,
  titleSlot,
  interviewers = [],
  title,
  date,
  viewers = [],
  externalViewers = [],
  share,
  menuOptions = [],
  onEdit,
  canEdit = false,
}) => {
  const isSmallScreen = useIsSmallScreen();

  const { ref: interviewersRef, isOverflowing: interviewersOverflowing } =
    useIsOverflowing<HTMLParagraphElement>({ width: true });

  const interviewerIds = new Set(interviewers.map(({ id }) => id));
  const interviewerNames = interviewers.map(({ fullName }) => fullName);

  if (isSmallScreen) {
    return (
      <MobileDetailsHeader
        candidate={candidate}
        position={position}
        interviewers={interviewers}
        title={title}
        titleSlot={titleSlot}
        date={date}
      />
    );
  }

  return (
    <Grid
      py="3"
      px="6"
      templateColumns="1fr min-content"
      templateAreas='
        "title    menu"
        "subtitle subtitle"'
    >
      <GridItem gridArea="title" display="flex" alignItems="center">
        {/* Candidate name */}
        <Text
          as="span"
          fontWeight="bold"
          fontSize="2xl"
          mr="4"
          data-tour-id="ip2-candidate-name"
        >
          {candidate?.id ? (
            <Tooltip label="View Candidate" openDelay={300}>
              <Link color="blue.600" href={`/candidate/${candidate.id}`}>
                {candidate.fullName || "Candidate"}
              </Link>
            </Tooltip>
          ) : canEdit ? (
            <Text
              display="inline"
              cursor="pointer"
              color="gray.400"
              _hover={{ color: "blue.500" }}
              _active={{ color: "blue.600" }}
              onClick={() => onEdit?.("candidate")}
            >
              Set Candidate
            </Text>
          ) : (
            <Text display="inline" color="gray.400">
              Unknown Candidate
            </Text>
          )}
        </Text>

        {titleSlot}
      </GridItem>

      <GridItem
        gridArea="subtitle"
        mt="2"
        display="flex"
        alignItems="center"
        fontWeight="medium"
        whiteSpace="nowrap"
      >
        {position?.id ? (
          <Link
            mr="4"
            minW="0"
            display="flex"
            alignItems="center"
            color="blue.600"
            href={`/position/${position.id}`}
          >
            <Icon as={HiOutlineBriefcase} mr="1" />
            <Text as="span" textOverflow="ellipsis" overflow="hidden">
              {position.displayTitle}
            </Text>
          </Link>
        ) : canEdit ? (
          <Text
            mr="4"
            display="flex"
            alignItems="center"
            cursor="pointer"
            color="gray.400"
            _hover={{ color: "blue.500" }}
            _active={{ color: "blue.600" }}
            onClick={() => onEdit?.("position")}
          >
            <Icon as={HiOutlineBriefcase} mr="1" />
            {position?.displayTitle ?? "Set Position"}
          </Text>
        ) : (
          <Text mr="4" display="flex" alignItems="center" color="gray.400">
            <Icon as={HiOutlineBriefcase} mr="1" />
            No Position
          </Text>
        )}

        {canEdit && !title ? (
          <Text
            cursor="pointer"
            color="gray.400"
            _hover={{ color: "blue.500" }}
            _active={{ color: "blue.600" }}
            onClick={() => onEdit?.("name")}
          >
            Set Title
          </Text>
        ) : (
          <Text color="gray.600" overflow="hidden" textOverflow="ellipsis">
            {title ?? "No Title"}
          </Text>
        )}

        <Text mx="4">
          {formatDate(date, {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </Text>

        {interviewerNames.length > 0 && (
          <Tooltip
            openDelay={500}
            label={
              interviewersOverflowing
                ? `Interviewers:\n${interviewerNames.join("\n")}`
                : "Interviewers"
            }
          >
            <Flex alignItems="center" maxW="50%">
              <Icon as={IoPeopleSharp} color="gray.500" boxSize="5" mr="2" />
              <Text
                ref={interviewersRef}
                as="span"
                color="gray.800"
                fontSize="md"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {interviewerNames.join(", ")}
              </Text>
            </Flex>
          </Tooltip>
        )}
      </GridItem>

      <GridItem gridArea="menu" display="flex" alignItems="center">
        <CallViewers
          externalViewers={externalViewers}
          internalViewers={viewers.map((viewer) => ({
            ...viewer,
            isInterviewer: interviewerIds.has(viewer.id),
          }))}
        />

        {share}

        {menuOptions.length > 0 && (
          <Menu placement="bottom-start">
            <MenuButton
              as={IconButton}
              icon={<MdMoreVert size="20" />}
              data-testid="call-settings-menu"
              variant="white"
              minW="8"
              h="8"
              data-tour-id="ip2-menu"
            />

            <MenuList>
              {menuOptions.map((option, idx) => ({
                ...option,
                key: idx,
              }))}
            </MenuList>
          </Menu>
        )}
      </GridItem>
    </Grid>
  );
};

export default DetailsHeader;
