import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { LeftArrow } from "../../../components/Icons/LeftArrow";
import EditTemplate from "../../components/Candidate/summary/EditTemplate";
import EditTemplateSaveAsModal from "../../components/Candidate/summary/EditTemplateSaveAsModal";
import { TemplateSections } from "../../components/Candidate/summary/types";
import { useUpdateSections } from "../../components/Candidate/summary/useUpdateSections";
import { primaryButtonGrayProps } from "../../components/utils";
import {
  CandidateSummaryTemplateSectionInput,
  useCandidateSummaryTemplateQuery,
  useGenerateCandidateSummaryMutation,
  useUpdateCandidateSummaryTemplateMutation,
} from "../../graphql";
import DefaultLayout from "../../layouts/DefaultLayout";
import { useUrlState } from "../home/useTabUrlState";

const buildInputSections = (
  sections: TemplateSections
): CandidateSummaryTemplateSectionInput[] => {
  if (!sections) return [];
  return sections.map((item) => {
    return {
      title: item.title,
      prompt: item.prompt,
      position: item.position,
      subsections:
        item.subsections.map((subitem) => {
          return {
            title: subitem.title,
            prompt: subitem.prompt,
            position: subitem.position,
          };
        }) || [],
    };
  });
};

const CandidateSummaryEditPage: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { templateId } = useParams() as {
    templateId: string;
  };
  const { data } = useCandidateSummaryTemplateQuery({
    variables: {
      templateId,
    },
  });
  const template = data?.candidateSummaryTemplate;
  const position = data?.candidateSummaryTemplate?.positions[0];
  const [candidateId] = useUrlState({
    key: "candidateId",
  });
  const [generateSummary] = useGenerateCandidateSummaryMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: err.message,
        status: "error",
      });
    },
  });

  const {
    currentSections,
    handleMoveSection,
    handleMoveSubsection,
    handleTitleChange,
    handlePromptChange,
    addSection,
    addSubsection,
    deleteSection,
    deleteSubsection,
  } = useUpdateSections(data?.candidateSummaryTemplate?.sections);

  const [updateTemplate, { loading }] =
    useUpdateCandidateSummaryTemplateMutation({
      onError: (err) => {
        toast({
          title: "Updating template error",
          description: err.message,
          status: "error",
        });
      },
      onCompleted: (data) => {
        if (data) {
          toast({
            title: "Success",
            description: "Updated the template",
            status: "success",
          });
          if (candidateId) {
            generateSummary({
              variables: { candidateId, positionId: position?.id, templateId },
            });
          }
          navigate(-1);
        }
      },
    });
  const [isDefault, setIsDefault] = useState(false);
  useEffect(() => {
    const defaultCheck =
      position &&
      template &&
      position.candidateSummaryTemplateId === template.id;
    setIsDefault(!!defaultCheck);
  }, [position, template]);

  const handleSave = (asNewTemplate: boolean, newTitle?: string): void => {
    if (template) {
      updateTemplate({
        variables: {
          templateId: template.id,
          sections: buildInputSections(currentSections),
          isDefault,
          asNewTemplate,
          positionId: position?.id,
          newTemplateName: newTitle,
        },
      });
    }
  };

  const [isSaveAsOpen, setIsSaveAsOpen] = useState(false);

  return (
    <DefaultLayout>
      {isSaveAsOpen && (
        <EditTemplateSaveAsModal
          currentTitle={template?.name || ""}
          onClose={() => setIsSaveAsOpen(false)}
          handleSave={handleSave}
        />
      )}
      <Flex
        h="64px"
        minH="64px"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="white"
        py={2}
        px={8}
        direction="row"
        borderBottom="1px"
        borderBottomColor="gray.100"
      >
        <Flex direction="row" alignItems="center">
          <IconButton
            aria-label="Back to candidate debrief page"
            icon={<LeftArrow ml={2} mt={3} fontSize="24px" />}
            alignItems="flex-start"
            {...primaryButtonGrayProps}
            onClick={() => navigate(-1)}
          />
          <Box ml="20px">Edit debrief template</Box>
          <Flex
            direction="column"
            ml={12}
            height={10}
            justifyContent="flex-start"
          >
            <Text fontSize="10px" color="gray.500" mb={1}>
              Position
            </Text>
            <Text fontSize="sm" color="gray.800">
              {position?.title || "No position"}
            </Text>
          </Flex>
        </Flex>
        <Flex direction="row" alignItems="center" gap={5}>
          <Button size="sm" variant="ghost" onClick={() => navigate(-1)}>
            Cancel
          </Button>
          <Button
            size="sm"
            variant="outline"
            onClick={() => setIsSaveAsOpen(true)}
          >
            Save as new template
          </Button>
          <Button
            isLoading={loading}
            size="sm"
            onClick={() => handleSave(false)}
          >
            Save
          </Button>
        </Flex>
      </Flex>
      <Flex flexDir="column" alignItems="center" px={8} height="95vh">
        <Flex dir="row" mb="10" mt="20px" maxW="874px" w="100%" height="95vh">
          <Flex
            direction="column"
            flex="1"
            transition="width 0.2s ease"
            border="1px solid"
            backgroundColor="white"
            borderColor="gray.200"
            borderRadius="12px"
            px={4}
            pt={5}
            pb={8}
            overflow="scroll"
            height="95vh"
            maxH="95vh"
          >
            {template && (
              <EditTemplate
                templateName={template.name}
                templateId={template.id}
                currentSections={currentSections}
                handleMoveSection={handleMoveSection}
                handleMoveSubsection={handleMoveSubsection}
                handleTitleChange={handleTitleChange}
                handlePromptChange={handlePromptChange}
                addSection={addSection}
                addSubsection={addSubsection}
                deleteSection={deleteSection}
                deleteSubsection={deleteSubsection}
                isDefault={isDefault}
                setIsDefault={setIsDefault}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </DefaultLayout>
  );
};
export default CandidateSummaryEditPage;
