import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  Grid,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useTheme,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

import { Card } from "../../../components";
import { SearchBox } from "../../../components/Search";
import SearchState from "../../../components/Search/SearchState";
import colors from "../../../theme/colors";
import { usePageTracker } from "../../../utils/googleAnalytics";
import { stringToTitleCase } from "../../../utils/string";
import { SearchResults } from "../../components/Search";
import { MetricName } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import DefaultLayout from "../../layouts/DefaultLayout";
import SearchFilters from "./SearchFilters";

const SearchPage: React.FC = () => {
  usePageTracker("search");
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    organization: { ats },
  } = useCurrentUser();
  // get metric query params
  const params = new URLSearchParams(window.location.search);
  const metricParam = params.get("metric");

  // replace metric query param with new metric

  const viewByOptions = [
    {
      value: "None",
      label: "None",
    },
    {
      value: MetricName.OnTimeInterviews,
      label: "On time starts",
    },
    {
      value: MetricName.CandidateTalkRatio,
      label: "Candidate talk ratio",
    },
    {
      value: MetricName.QuestionsAsked,
      label: "Questions asked",
    },
    {
      value: MetricName.LongestMonologue,
      label: "Longest monologue",
    },
    {
      value: MetricName.SpeakingRate,
      label: "Speaking rate",
    },
    {
      value: MetricName.Interactivity,
      label: "Interactivity",
    },
  ];

  if (ats) {
    viewByOptions.push(
      {
        value: MetricName.PassRate,
        label: "Positive Feedback Rate",
      },
      {
        value: MetricName.ScorecardCompletionTime,
        label: "Feedback submission time",
      },
      {
        value: MetricName.ScorecardCompletionRate,
        label: "Feedback submission rate",
      }
    );
  }

  const [viewByOption, setViewByOption] = useState({
    value:
      viewByOptions.find((option) => option.value === metricParam)?.value ||
      "Select",
    label: stringToTitleCase(
      viewByOptions.find((option) => option.value === metricParam)?.label ||
        "Select"
    ),
  });

  useEffect(() => {
    const allParams = params.toString();

    // replace metric query param with new metric
    if (allParams.includes("metric")) {
      let newParams = allParams;
      if (viewByOption.value === "Select") {
        newParams = allParams.replace(`&metric=${metricParam as string}`, "");
        newParams = newParams.replace(`source=insights`, "");
        if (newParams[0] === "&") {
          newParams = newParams.substring(1, newParams.length);
        }
      } else {
        newParams = allParams.replace(
          `metric=${metricParam as string}`,
          `metric=${viewByOption.value}`
        );
      }
      navigate(`/search?${newParams}`, {
        replace: true,
      });
    } else if (viewByOption.value !== "Select") {
      navigate(`/search?${allParams}&metric=${viewByOption.value}`, {
        replace: true,
      });
    } else {
      navigate(`/search?${allParams}`, {
        replace: true,
      });
    }
  }, [viewByOption]);
  const { pagePadding } = theme.sizes;
  const linkIsFromInsights = params.getAll("source").includes("insights");
  const linkIsFromGenderSkewAlert = params
    .getAll("source")
    .includes("gender_skew_alert");
  const metric = params.get("metric");
  let metricName = stringToTitleCase(metric || "");

  // swap out metric name for updated names
  if (metric === MetricName.ScorecardCompletionRate) {
    metricName = "Feedback Submission Rate";
  } else if (metric === MetricName.ScorecardCompletionTime) {
    metricName = "Feedback Submission Time";
  } else if (metric === MetricName.PassRate) {
    metricName = "Positive Feedback Rate";
  }

  return (
    <DefaultLayout allowEmptyQuery updateUrlWithSearchParams>
      <Grid
        p={pagePadding}
        gap="5"
        overflowX="auto"
        templateColumns="300px minmax(auto, 1fr)"
      >
        <Box>
          <SearchFilters />
        </Box>
        <Card pt={4} px={8}>
          <Box
            width="100%"
            fontSize="sm"
            pb={4}
            borderBottom="1px"
            borderColor="border"
          >
            <Flex
              width="100%"
              fontSize="sm"
              fontWeight="bold"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box mr="auto" flexBasis="400px" flexShrink={2} flexGrow={0}>
                <SearchBox />
              </Box>
              <Flex pl={4} whiteSpace="nowrap" alignItems="center">
                <Flex mr="5" alignItems="center">
                  Show Metric:
                  <Box ml="3">
                    <Menu>
                      <MenuButton
                        as={Button}
                        size="sm"
                        variant="unstyled"
                        color="blue.700"
                        borderRadius="0"
                        borderBottom="1px solid"
                        rightIcon={<HiOutlineChevronDown />}
                      >
                        {viewByOption.label}
                      </MenuButton>
                      <MenuList>
                        {viewByOptions.map((opt) => (
                          <MenuItem
                            onClick={() => setViewByOption(opt)}
                            key={opt.label}
                          >
                            {opt.label}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </Box>
                </Flex>
              </Flex>
            </Flex>
            <SearchState
              render={({ searchResults }) => {
                const nbHits = searchResults?.nbHits;
                let callCount;
                if (nbHits !== undefined) {
                  if (nbHits === 0) {
                    if (searchResults.query === "") {
                      // This will only trigger if the user doesn't have access to any interviews
                      callCount = <Text>No interviews found.</Text>;
                    } else {
                      callCount =
                        "No results found. Interviews with that search term either do not exist or you may not have access to them or may have been deleted based on your company's data protection policies.";
                    }
                  } else if (nbHits === 1) {
                    callCount = "1 result found";
                  } else {
                    callCount = `${nbHits} results found`;
                  }
                }
                return (
                  <Box pt={4} width="100%" textAlign="center">
                    {linkIsFromInsights && (
                      <SearchPageAlert>
                        For our calculation of {metricName || "metrics"}, we
                        filter out interviews in which a candidate has not been
                        identified and labeled,
                        {(metric === MetricName.ScorecardCompletionRate ||
                          metric === MetricName.ScorecardCompletionTime ||
                          metric === MetricName.PassRate) &&
                          " and interviews without a completed scorecard,"}{" "}
                        but we include redacted interviews. As a result of this,
                        you may see a different number of interviews on the
                        Search details page.
                      </SearchPageAlert>
                    )}
                    {linkIsFromGenderSkewAlert && (
                      <SearchPageAlert>
                        Some interviews for the selected candidate(s) may not
                        have been recorded. Check your ATS for the full hiring
                        team panel.
                      </SearchPageAlert>
                    )}
                    {callCount}
                  </Box>
                );
              }}
            />
          </Box>
          <SearchResults />
        </Card>
      </Grid>
    </DefaultLayout>
  );
};

export default SearchPage;

interface SearchPageAlertProps {
  children: React.ReactNode;
}

const SearchPageAlert: React.FC<SearchPageAlertProps> = ({
  children,
}): JSX.Element => (
  <Flex mt="6" mb="6">
    <Alert
      status="info"
      variant="subtle"
      flex="1"
      borderRadius="4"
      bgColor={colors.gray[50]}
      width="80%"
    >
      <AlertIcon />
      <AlertDescription fontSize="sm" textAlign="left">
        {children}
      </AlertDescription>
    </Alert>
  </Flex>
);
