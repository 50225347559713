import { Flex } from "@chakra-ui/react";
import React from "react";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { AtsDataState, MetricName } from "../../graphql";
import DefaultLayout from "../../layouts/DefaultLayout";
import MainContent from "../training/MainContent";
import AnalyticsAdvancedSidebar from "./AnalyticsAdvancedSidebar";
import AnalyticsSidebar from "./AnalyticsSidebar";

export type AnalyticsSidebarType = "none" | "default" | "advanced";

type AnalyticsPageLayout = {
  children?: React.ReactNode;
  metric: MetricName;
  maxW?: string;
  sidebarType?: AnalyticsSidebarType;
  grayBg: boolean;
  atsDataState: AtsDataState;
};

export const AnalyticsPageLayout: React.FC<AnalyticsPageLayout> = ({
  maxW,
  children,
  metric,
  grayBg,
  sidebarType = "default",
  atsDataState,
}) => {
  const { layoutHeight } = useWindowDimensions();
  return (
    <DefaultLayout>
      <Flex minHeight={layoutHeight} maxH="100vh" overflow="auto">
        {sidebarType === "default" && (
          <AnalyticsSidebar
            path={metric}
            atsDataState={atsDataState}
            width="240px"
            minWidth="240px"
            mr="0"
          />
        )}
        {sidebarType === "advanced" && (
          <AnalyticsAdvancedSidebar width="240px" minWidth="240px" mr="0" />
        )}
        <MainContent
          data-testid="analytics--page-content"
          pt="4"
          px="6"
          minWidth={{ base: "100%", lg: "800px" }}
          borderColor="gray.200"
          bg={grayBg ? "unset" : "white"}
        >
          <Flex
            flexDir="column"
            mx="auto"
            maxWidth={maxW || "1012px"}
            boxSizing="initial"
            pb="100px"
          >
            {children}
          </Flex>
        </MainContent>
      </Flex>
    </DefaultLayout>
  );
};
