import { ApolloError } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { Alert } from "../../../components";
import {
  phoneExtension as phoneExtensionRegex,
  phoneNumber as phoneNumberRegex,
} from "../../../utils/regex";
import { TimezoneSelect } from "../../components";
import { UserPersona } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

export interface FormData {
  firstName: string;
  lastName: string;
  emailAliases: string;
  timezoneName: string;
  persona: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  maskPersonalPhoneNumber: boolean;
  phoneNumberSkipped: boolean;
}

interface UserSettingsFormProps {
  firstName: string;
  lastName: string;
  emailAliases: string;
  timezoneName: string;
  persona: UserPersona | null | undefined;
  error: ApolloError | undefined;
  isLoading: boolean;
  onSubmit: (formData: FormData) => void;
  phoneNumber: string;
  phoneNumberExtension: string;
  maskPersonalPhoneNumber: boolean;
}

const UserSettingsForm: React.FC<UserSettingsFormProps> = ({
  firstName,
  lastName,
  emailAliases,
  timezoneName,
  persona,
  error,
  isLoading,
  onSubmit,
  phoneNumber,
  phoneNumberExtension,
  maskPersonalPhoneNumber,
}) => {
  const currentUser = useCurrentUser();

  const orgName = currentUser.organization?.name || "your organization";

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      firstName,
      lastName,
      emailAliases,
      timezoneName,
      persona: persona?.toString(),
      phoneNumber,
      phoneNumberExtension,
      maskPersonalPhoneNumber,
    },
  });

  const voipPhoneNumber = currentUser.voipPhoneNumber || "";

  let callerId = "";
  if (currentUser.voipPhoneNumber) {
    callerId = voipPhoneNumber;
  } else if (maskPersonalPhoneNumber) {
    callerId = currentUser.organization.phoneNumber
      ? currentUser.organization.phoneNumber
      : `(973) 629-8964`;
  } else {
    callerId = phoneNumber;
  }

  return (
    <form
      autoComplete="on"
      onSubmit={handleSubmit((formData) => {
        onSubmit({
          ...formData,
          phoneNumberSkipped: formData.phoneNumber === "",
        });
        onSubmit(formData);
      })}
    >
      {error?.graphQLErrors?.map(({ message }, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Alert mb={6} key={i} status="error" description={message} />
      ))}
      <VStack spacing="8" align="start" maxW="400">
        <FormControl
          id="firstName"
          isRequired
          isInvalid={errors.firstName !== undefined}
        >
          <FormLabel>First name</FormLabel>
          <Input {...register("firstName")} autoComplete="given-name" />
          {errors.firstName !== undefined && (
            <FormErrorMessage>{errors.firstName.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          id="lastName"
          isRequired
          isInvalid={errors.lastName !== undefined}
        >
          <FormLabel>Last name</FormLabel>
          <Input {...register("lastName")} autoComplete="family-name" />
          {errors.lastName !== undefined && (
            <FormErrorMessage>{errors.lastName.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          id="emailAliases"
          isInvalid={errors.emailAliases !== undefined}
        >
          <FormLabel>Email aliases</FormLabel>
          <Input {...register("emailAliases")} />
          {errors.emailAliases !== undefined && (
            <FormErrorMessage>{errors.emailAliases.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl
          id="timezoneName"
          isInvalid={errors.timezoneName !== undefined}
        >
          <FormLabel>Timezone</FormLabel>
          <Controller
            control={control}
            name="timezoneName"
            render={({ field: { onChange, value } }) => (
              <TimezoneSelect
                timezone={value}
                onChange={onChange}
                data-testid="user-timezone-select"
              />
            )}
          />
          <FormHelperText>
            Note: this setting does not affect dates displayed in the
            application.
          </FormHelperText>
        </FormControl>
        <FormControl id="persona" isInvalid={errors.persona !== undefined}>
          <FormLabel>Persona</FormLabel>
          <Select {...register("persona")} isDisabled={isLoading}>
            {!persona && (
              <option key="-" value="">
                Please select an option
              </option>
            )}
            <option key="INTERVIEWER" value="INTERVIEWER">
              Interviewer
            </option>
            <option key="COORDINATOR_OPS" value="COORDINATOR_OPS">
              Recruiting Coordinator/Operations
            </option>
            <option key="RECRUITER" value="RECRUITER">
              Recruiter
            </option>
            <option key="HIRING_MANAGER" value="HIRING_MANAGER">
              Hiring Manager
            </option>
            <option key="TA_LEADER" value="TA_LEADER">
              Executive/TA Leader
            </option>
          </Select>
          {errors.persona !== undefined && (
            <FormErrorMessage>{errors.persona.message}</FormErrorMessage>
          )}
          <FormHelperText>
            Select the persona that most closely aligns with how you will use
            BrightHire. If you frequently interview but also align with another
            persona, select the non-interviewer persona.
          </FormHelperText>
        </FormControl>
      </VStack>
      <VStack spacing="8" mt="8" align="start">
        <Flex>
          <FormControl
            id="phoneNumber"
            isInvalid={errors.phoneNumber !== undefined}
          >
            <FormLabel>Phone number</FormLabel>
            <Input
              type="tel"
              {...register("phoneNumber", {
                pattern: {
                  value: phoneNumberRegex,
                  message: "Invalid phone number",
                },
              })}
              autoComplete="tel"
              aria-describedby="phone-helper-text"
              placeholder="(xxx) xxx-xxxx"
            />
            {errors.phoneNumber !== undefined && (
              <FormErrorMessage>{errors.phoneNumber.message}</FormErrorMessage>
            )}
            <FormHelperText id="phone-helper-text">
              Shown by Caller ID when calling a candidate.
            </FormHelperText>
          </FormControl>
          <FormControl
            id="phoneNumberExtension"
            ml={4}
            width="100px"
            isInvalid={errors.phoneNumberExtension !== undefined}
          >
            <FormLabel>Extension</FormLabel>
            <Input
              {...register("phoneNumberExtension", {
                pattern: {
                  value: phoneExtensionRegex,
                  message: "Invalid extension",
                },
              })}
              autoComplete="tel-extension"
            />
            {errors.phoneNumberExtension !== undefined && (
              <FormErrorMessage>
                {errors.phoneNumberExtension.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>
        <FormControl
          id="maskPersonalPhoneNumber"
          isInvalid={errors.maskPersonalPhoneNumber !== undefined}
        >
          <Checkbox
            {...register("maskPersonalPhoneNumber")}
            defaultChecked={maskPersonalPhoneNumber}
          >
            Mask personal phone number when making phone calls using BrightHire
          </Checkbox>
          <FormHelperText>
            Enabling this setting ensures that phone calls made through
            BrightHire will appear as {`${orgName} `}&apos;s default phone
            number. This prevents candidates from accessing the personal phone
            number of the interviewer.
          </FormHelperText>
        </FormControl>
        {!!voipPhoneNumber && (
          <div>
            You have an assigned Brighthire Number{" "}
            <strong>{voipPhoneNumber}</strong>
          </div>
        )}
        {callerId && (
          <Box data-testid="caller-id">
            Your Caller ID will show as <strong>{callerId}</strong>
          </Box>
        )}
        <Button type="submit" isLoading={isLoading} data-testid="save">
          Save
        </Button>
      </VStack>
    </form>
  );
};

export default UserSettingsForm;
