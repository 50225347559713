import { BoxProps, Flex } from "@chakra-ui/react";
import React from "react";

import {
  InviteUsersButton,
  LoadingIndicator,
  SearchInput,
  useToast,
} from "../../../components";
import { useSearchParam } from "../../../hooks/useSearchParam";
import { UserList } from "../../components";
import {
  PlanUserRolePermission,
  PositionRolePermission,
  useInviteUsersMutation,
  useOrgUsersQuery,
  UserRoleName,
  UserRolePermission,
} from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import SettingsPageContainer from "../settings/shared/SettingsPageContainer";

const UsersListPage: React.FC<BoxProps> = (styles) => {
  const toast = useToast();
  const currentUser = useCurrentUser();
  const [query, setQuery] = useSearchParam("q");
  const { loading: orgUsersLoading, data } = useOrgUsersQuery({
    variables: {
      query,
      deleted: false,
      signUpCompleted: false,
    },
    onError: (err) => {
      toast({
        title: "Error",
        description: `Failed to load user data: ${err.message}`,
        status: "error",
      });
    },
  });
  const [inviteUsers] = useInviteUsersMutation({
    update(cache, { data: inviteUsersData }) {
      const newUsers = inviteUsersData?.inviteUsers?.users;
      if (newUsers) {
        cache.modify({
          id: cache.identify(currentUser.organization),
          fields: {
            users: (prevUsers) => {
              return [...prevUsers, ...newUsers];
            },
          },
        });
      }
    },
  });

  const users = data?.currentUser?.organization?.users || [];
  const canManageUserRoles = currentUser.userRole?.permissions?.includes(
    UserRolePermission.ManageUserRoles
  );
  const roles = canManageUserRoles
    ? currentUser.organization.userRoles
    : currentUser.organization.userRoles.filter(
        (r) => r.name === UserRoleName.Basic
      );
  const canInviteUsers =
    currentUser.userRole?.permissions?.includes(
      UserRolePermission.ManageHiringTeam
    ) ||
    currentUser.memberships.reduce((acc, membership) => {
      return (
        acc ||
        membership.role.permissions.includes(
          PositionRolePermission.ManageHiringTeam
        )
      );
    }, false);

  const planSkuEnabled = data?.currentUser?.organization?.planEnabled;
  const canManagePlanUserRoles =
    currentUser.planUserRole?.permissions?.includes(
      PlanUserRolePermission.ManageUserRoles
    );
  const planUserRoles = [
    {
      id: "",
      name: "NO_ACCESS",
      permissions: [],
      formattedPermissions: "No access to BrightHire Plan",
      formattedName: "No access",
    },
    ...(currentUser.organization.planUserRoles || []),
  ];
  const planRoles = canManagePlanUserRoles
    ? planUserRoles
    : planUserRoles.filter((r) => r.name === "NO_ACCESS");

  return (
    <SettingsPageContainer
      maxW="fit-content"
      minW="max(900px, 100%)"
      heading="Users list"
      subHeading="View and manage all active and invited users."
    >
      <Flex alignItems="center" justifyContent="space-between" mb="4">
        <SearchInput
          onSearch={setQuery}
          defaultValue={query}
          placeholder="Search names, emails"
        />
        <InviteUsersButton
          inviteUsers={(users) => {
            return inviteUsers({ variables: { users } }).then((res) => {
              if (res.errors) {
                throw res.errors[0].message;
              }
            });
          }}
          roles={roles}
          includePlanRoleField={planSkuEnabled}
          planRoles={planRoles}
          disabled={!canInviteUsers}
        />
      </Flex>
      {orgUsersLoading ? (
        <LoadingIndicator />
      ) : (
        <UserList
          users={users}
          canDisableUsers={canManageUserRoles}
          planSkuEnabled={planSkuEnabled}
        />
      )}
    </SettingsPageContainer>
  );
};

export default UsersListPage;
