import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  ValueType,
  // eslint-disable-next-line import/no-unresolved
} from "recharts/types/component/DefaultTooltipContent";
// eslint-disable-next-line import/no-unresolved
import { Props } from "recharts/types/component/Label";

import { BetaTag, SelectDateRange } from "../../../components";
import AILoading from "../../../components/LoadingIndicator/AILoading";
import { dateRange90DaysAgo } from "../../../components/SelectDateRange/SelectDateRange";
import {
  useSearchParam,
  useSelectDateRangeParams,
} from "../../../hooks/useSearchParam";
import colors from "../../../theme/colors";
import { formatISODate } from "../../../utils/datetime";
import {
  AnalyticsDimension,
  MetricName,
  useAnalyticsFilterValuesQuery,
  useClusteredDataLazyQuery,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import AnalyticsAdmin from "./overview/AnalyticsAdmin";
import useAnalyticsContext from "./useAnalyticsContext";
import { useAnalyticsSelectTheme } from "./useAnalyticsSelectTheme";

const groupingOptions = [
  { id: "call", value: "call", label: "Percent of interviews" },
  {
    id: "candidate",
    value: "candidate",
    label: "Percent of candidates",
  },
];

const AnalyticsMetricsTopics: React.FC = () => {
  const [selectTheme, selectStyles] = useAnalyticsSelectTheme();
  const toast = useToast();
  const [dateRange, setDateRange] = useSelectDateRangeParams(
    "dateRange",
    dateRange90DaysAgo()
  );
  const [selectedPosition, setSelectedPosition] = useSearchParam("position");
  const [selectedGrouping, setSelectedGrouping] = useSearchParam(
    "grouping",
    "candidate"
  );
  const navigate = useNavigate();
  const pageEnabled = useFeatureFlag("analytics:experimental");
  useEffect(() => {
    if (!pageEnabled) {
      navigate({
        pathname: `/insights/overview`,
        search: "",
      });
    }
  }, [pageEnabled]);

  const location = useLocation();
  const metric = useMemo(() => {
    return location.pathname.split("/").pop()?.toUpperCase() as MetricName;
  }, [location]);

  const { selectedOrgId } = useAnalyticsContext();
  const [queryClusteredData, clusteredData] = useClusteredDataLazyQuery();

  const positionsFilterQuery = useAnalyticsFilterValuesQuery({
    variables: {
      metric: MetricName.TotalInterviews,
      primaryDimension: AnalyticsDimension.Position,
      secondaryDimension: AnalyticsDimension.None,
      organizationId: selectedOrgId,
      positions: [],
      interviewers: [],
      departments: [],
      stages: [],
      dateRangeStart: dateRange ? formatISODate(dateRange.start) : null,
      dateRangeEnd: dateRange ? formatISODate(dateRange.end) : null,
    },
  });

  const compute = useCallback(() => {
    if (!selectedPosition) {
      toast({
        title: "Please select a position",
        status: "error",
        position: "top",
      });
      return;
    }
    if (!selectedGrouping) {
      toast({
        title: "Please select a grouping",
        status: "error",
        position: "top",
      });
      return;
    }
    if (!dateRange) {
      toast({
        title: "Please select a grouping",
        status: "error",
        position: "top",
      });
      return;
    }
    queryClusteredData({
      variables: {
        topicsOrQuestions:
          metric === MetricName.TopicTrends ? "topics" : "questions",
        countBy: selectedGrouping,
        organizationId: selectedOrgId,
        positionDisplayTitle: selectedPosition,
        dateRangeStart: formatISODate(dateRange.start),
        dateRangeEnd: formatISODate(dateRange.end),
      },
      onError: () => {
        toast({
          status: "error",
          title: "Error running report",
          position: "top",
        });
      },
    });
  }, [
    selectedPosition,
    selectedGrouping,
    dateRange,
    metric,
    queryClusteredData,
  ]);

  const selectedPositionOption = useMemo(() => {
    return positionsFilterQuery.data?.filterValues.values.find(
      (v) => v.id === selectedPosition
    );
  }, [positionsFilterQuery, selectedPosition]);

  const selectedGroupingOption = useMemo(() => {
    return groupingOptions.find((v) => v.id === selectedGrouping);
  }, [selectedGrouping]);

  const chartData = clusteredData?.data?.clusteredData?.data;
  const height = (chartData?.length ?? 0) * 24;

  return (
    <>
      <Flex
        flexDir="row"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex flexDir="row" alignItems="baseline" width="100%">
          <Text fontSize="24px" fontWeight="700" color="gray.700" pr="2">
            {metric === MetricName.TopicTrends ? "Topic" : "Question"} Trends
          </Text>
          <Box top="-4px">
            <BetaTag>Beta</BetaTag>
          </Box>
          <Box marginLeft="auto">
            <AnalyticsAdmin />
          </Box>
        </Flex>
      </Flex>

      <Flex mt={4}>
        <Box data-testid="analytics--filter-position" width="250px">
          <Select
            theme={selectTheme}
            styles={selectStyles}
            placeholder="Select a position"
            isSearchable
            value={selectedPositionOption}
            options={positionsFilterQuery.data?.filterValues.values}
            isLoading={positionsFilterQuery.loading}
            onChange={(selectedOption) => {
              setSelectedPosition(selectedOption?.value ?? undefined);
            }}
          />
        </Box>
        <Box data-testid="analytics--filter-position" width="250px" ml={2}>
          <Select
            theme={selectTheme}
            styles={selectStyles}
            placeholder="Count interviews or candidates"
            value={selectedGroupingOption}
            options={groupingOptions}
            onChange={(selectedOption) => {
              setSelectedGrouping(selectedOption?.id ?? undefined);
            }}
          />
        </Box>
        <Flex minW="148px" position="relative" ml={2} top="-1px">
          <SelectDateRange
            state={dateRange ?? dateRange90DaysAgo()}
            onSelect={setDateRange}
            includeLabel={false}
            selectStyles={selectStyles}
            selectTheme={selectTheme}
            rangeStyleProps={{ wrap: "nowrap" }}
            dateInputStyleProps={{
              fontSize: "14px",
              color: colors.gray[900],
              borderColor: colors.gray[100],
            }}
          />
        </Flex>
        <Button
          onClick={() => compute()}
          ml={2}
          isDisabled={!selectedPosition}
          isLoading={clusteredData.loading}
          alignSelf="center"
          top="-1px"
          size="sm"
        >
          Compute
        </Button>
      </Flex>

      {clusteredData.loading && (
        <Flex width="100%" mt={8} alignItems="center" justifyContent="center">
          <AILoading />
        </Flex>
      )}
      {!clusteredData.loading && !chartData && (
        <Flex width="100%" mt={8} alignItems="center" justifyContent="center">
          <Flex>
            No data yet. Please make some selections and click compute.
          </Flex>
        </Flex>
      )}
      {!clusteredData.loading && chartData && chartData.length < 1 && (
        <Flex width="100%" mt={8} alignItems="center" justifyContent="center">
          <Flex>
            No data available for this position in this time period. Please
            select a different position or time period.
          </Flex>
        </Flex>
      )}
      {!clusteredData.loading && chartData && (
        <Box mt="5">
          <ResponsiveContainer height={height}>
            <BarChart
              data={chartData || []}
              height={height}
              margin={{
                top: 0,
                right: 0,
                left: 280,
                bottom: 0,
              }}
              layout="vertical"
              barGap={0}
              barCategoryGap={0}
              barSize={10}
            >
              <XAxis
                type="number"
                axisLine={false}
                display="none"
                domain={[0, 120]}
              />
              <YAxis
                type="category"
                dataKey="label"
                axisLine={false}
                display="none"
              />
              <Tooltip
                content={CustomTooltip(
                  metric === MetricName.TopicTrends,
                  selectedGrouping === "call"
                )}
                isAnimationActive={false}
              />
              <Bar dataKey="value" fill="#B37FEB">
                <LabelList
                  dataKey="label"
                  position="left"
                  content={renderCustomizedLabel}
                />

                <LabelList
                  dataKey="value"
                  formatter={(v: number) => {
                    return `${Math.round(v)}%`;
                  }}
                  position="right"
                  fill="#505863"
                  textAnchor="middle"
                  fontSize={14}
                />

                {chartData?.map((entry: any, index: number) => (
                  <Cell
                    fill={getFillColor(entry.value)}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`cell-${index}`}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      )}
    </>
  );
};

const CustomTooltip = (isTopics: boolean, isCalls: boolean) => {
  return (
    props: TooltipProps<ValueType, NameType>
  ): React.ReactElement | null => {
    if (props.active) {
      return (
        <Box
          bgColor="white"
          borderRadius={4}
          border={`1px solid ${colors.gray[200]}`}
          p={3}
          maxW="450px"
        >
          <Box fontWeight={500} fontSize="sm" color={colors.purple[400]}>
            {isTopics ? "Topic" : "Question"}
          </Box>
          <Box fontWeight={400} fontSize="xs" mt={1} color={colors.gray[900]}>
            {props.label}
          </Box>
          <Box fontWeight={400} fontSize="xs" mt={2} color={colors.gray[700]}>
            Discussed {isCalls ? "in" : "with"}{" "}
            {Math.round(props?.payload?.[0].payload.value ?? 0)}% of{" "}
            {isCalls ? "interviews" : "candidates"}
          </Box>
        </Box>
      );
    }

    return null;
  };
};

interface Color {
  r: number;
  g: number;
  b: number;
}

const hexToRgb = (hex: string): Color | null => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const colorGradient = (
  fadeFraction: number,
  rgbColor1: Color,
  rgbColor2: Color,
  rgbColor3: Color
): string => {
  let color1 = rgbColor1;
  let color2 = rgbColor2;
  let fade = fadeFraction;

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade *= 2;

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = rgbColor2;
      color2 = rgbColor3;
    }
  }

  const diffRed = color2.r - color1.r;
  const diffGreen = color2.g - color1.g;
  const diffBlue = color2.b - color1.b;

  const gradient: Color = {
    r: Math.floor(color1.r + diffRed * fade),
    g: Math.floor(color1.g + diffGreen * fade),
    b: Math.floor(color1.b + diffBlue * fade),
  };

  return `rgb(${gradient.r},${gradient.g},${gradient.b})`;
};

const getFillColor = (v: number): string => {
  return colorGradient(
    v / 100,
    hexToRgb(colors.red[400]) ?? { r: 100, g: 0, b: 0 },
    hexToRgb(colors.yellow[400]) ?? { r: 0, g: 100, b: 100 },
    hexToRgb(colors.green[400]) ?? { r: 0, g: 100, b: 0 }
  );
};

const renderCustomizedLabel = (props: Props): React.ReactElement | null => {
  const { x, y, value } = props;

  if (!x || !y || !value) return null;
  if (typeof x === "string") return null;
  if (typeof y === "string") return null;
  if (typeof value !== "string") return null;

  return (
    <g>
      <text
        x={x - 10}
        y={y + 10}
        fill="#505863"
        textAnchor="end"
        dominantBaseline="bottom"
      >
        {value.slice(0, 38)}
        {value.length > 40 ? "..." : null}
      </text>
    </g>
  );
};

export default AnalyticsMetricsTopics;
