import { Button, ButtonProps, Text } from "@chakra-ui/react";
import React from "react";

import { FilterIcon } from "./icons";

type ToggleFiltersButtonProps = {
  open: boolean;
  toggleFilters(): void;
} & ButtonProps;

const ToggleFiltersButton: React.FC<ToggleFiltersButtonProps> = ({
  open,
  toggleFilters,
  ...buttonProps
}) => {
  return (
    <Button
      bg={open ? "gray.50" : undefined}
      color={open ? "gray.400" : undefined}
      variant="ghost"
      ml="auto"
      mr="6"
      fontWeight="semibold"
      onClick={toggleFilters}
      {...buttonProps}
    >
      <FilterIcon mr="2" color={open ? "gray.500" : undefined} />
      <Text fontSize="sm">Filters</Text>
    </Button>
  );
};

export default ToggleFiltersButton;
