import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import {
  PiArrowsInLineVerticalFill,
  PiArrowsOutLineVerticalFill,
} from "react-icons/pi";

import { primaryButtonGrayProps } from "../../../../plan/components/utils";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import ColumnHeader from "./ColumnHeader";
import { SubTopic, Topic } from "./types";
import { encodeToAnchor } from "./utils";

type TopicCoverageProps = {
  topics: Topic[];
};

const TopicCoverageList: React.FC<TopicCoverageProps> = ({ topics }) => {
  const [expandedIndices, setExpandedIndices] = React.useState<number[]>([]);

  const toggleAll = (): void => {
    if (expandedIndices.length === 0) {
      setExpandedIndices(topics.map((_, i) => i));
    } else {
      setExpandedIndices([]);
    }
  };

  const allClosed = expandedIndices.length === 0;

  return (
    <Box overflow="auto" px={6}>
      <Flex flexDir="row" mb={2}>
        <ColumnHeader>Topics &amp; Coverage</ColumnHeader>
        <Tooltip label={allClosed ? "Expand topics" : "Collapse topics"}>
          <IconButton
            ml="auto"
            aria-label="Expand questions"
            icon={
              <Icon
                as={
                  allClosed
                    ? PiArrowsOutLineVerticalFill
                    : PiArrowsInLineVerticalFill
                }
                boxSize={5}
              />
            }
            size="sm"
            onClick={toggleAll}
            {...primaryButtonGrayProps}
          />
        </Tooltip>
      </Flex>
      <Accordion
        allowMultiple
        index={expandedIndices}
        onChange={(indices: number[]) => setExpandedIndices(indices)}
      >
        {topics.map((topic) => {
          let citationCount = 0;
          topic.subTopics.forEach((subTopic) => {
            subTopic.notes.forEach((note) => {
              citationCount += note.citations.length;
            });
          });
          return (
            <TopicCoverage
              key={topic.name}
              topic={topic}
              numCitations={citationCount}
            />
          );
        })}
      </Accordion>
    </Box>
  );
};

type TopicNoteCount = {
  topic: Topic;
  numCitations: number;
};

const TopicCoverage: React.FC<TopicNoteCount> = ({ topic, numCitations }) => {
  const { name } = topic;
  return (
    <AccordionItem border="none">
      {({ isExpanded }: { isExpanded: boolean }) => (
        <>
          <AccordionHeader
            title={name}
            isExpanded={isExpanded}
            numCitations={numCitations}
          />
          <AccordionPanel py={0} px={0}>
            {topic.subTopics.map((subTopic) => (
              <SubTopicCoverage key={subTopic.name} subtopic={subTopic} />
            ))}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

const AccordionHeader: React.FC<{
  title: string;
  isExpanded: boolean;
  numCitations: number;
}> = ({ title, isExpanded, numCitations }) => {
  const sendGAEvent = useSendGAEvent();
  return (
    <Text role="heading" fontSize="md" color="gray.800" pb="1">
      <AccordionButton
        _hover={{ bg: "transparent", color: "blue.600" }}
        borderRadius="base"
        py="0"
        height="10"
        px={0}
        onClick={() => {
          sendGAEvent(
            isExpanded
              ? "candidate_summary_topic_collapse"
              : "candidate_summary_topic_expand",
            "candidate"
          );
        }}
      >
        <AccordionIcon
          transform={isExpanded ? "rotate(0deg)" : "rotate(-90deg)"}
        />
        <Flex
          flex="1"
          alignItems="center"
          justifyContent="flex-start"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Text
            fontSize="sm"
            textAlign="left"
            textOverflow="ellipsis"
            overflow="hidden"
            marginRight="auto"
            color="gray.800"
            fontWeight={400}
            onClick={() => {
              sendGAEvent("candidate_summary_topic_click", "candidate");
            }}
            _hover={{
              color: "blue.600",
            }}
          >
            <a href={`#${encodeToAnchor("topic", title)}`}>{title}</a>
          </Text>
          <Box ml="2">
            <BarMeter
              displayNumber={numCitations}
              percentage={Math.min(1, numCitations / 6)}
              size="md"
            />
          </Box>
        </Flex>
      </AccordionButton>
    </Text>
  );
};

type SubTopicCoverage = {
  subtopic: SubTopic;
};

const SubTopicCoverage: React.FC<SubTopicCoverage> = ({ subtopic }) => {
  const sendGAEvent = useSendGAEvent();
  let citations = 0;
  subtopic.notes.forEach((note) => {
    citations += note.citations.length;
  });
  return (
    <Box borderLeftWidth="1px" borderColor="gray.100" pl={4} ml="9px">
      <Flex py={2} flexDir="row" alignItems="flex-start" gap="16px">
        <Text
          fontSize="sm"
          overflow="hidden"
          marginRight="auto"
          color="gray.800"
          fontWeight={400}
          flexShrink="1"
          _hover={{
            color: "blue.600",
          }}
        >
          <a
            href={`#${encodeToAnchor("subtopic", subtopic.name)}`}
            onClick={() => {
              sendGAEvent("candidate_summary_subtopic_click", "candidate");
            }}
          >
            {subtopic.name}
          </a>
        </Text>
        <Box ml="2">
          <BarMeter
            displayNumber={citations}
            percentage={Math.min(1, citations / 3)}
            size="sm"
            fillColor="gray.400"
          />
        </Box>
      </Flex>
    </Box>
  );
};

type BarMeter = {
  displayNumber: number;
  percentage: number;
  size?: "md" | "sm";
  fillColor?: string;
};

const BarMeter: React.FC<BarMeter> = ({
  displayNumber,
  percentage,
  size = "md",
  fillColor = "blue.400",
}) => {
  const height = size === "md" ? 8 : 4;
  const filledWidth = Math.max(height, percentage * 24);
  return (
    <Flex flexShrink="0" gap="4px" alignItems="center" height="21px">
      <Box
        width={6}
        bg="gray.200"
        height={height / 4}
        position="relative"
        borderRadius={height}
      >
        <Box
          borderRadius={height}
          bg={fillColor}
          width={`${filledWidth}px`}
          height={height / 4}
          position="absolute"
          left={0}
          top={0}
        />
      </Box>
      <Tooltip label={`${displayNumber} citations found for this topic`}>
        <Text fontSize="2xs" color="gray.600" minWidth={3} textAlign="right">
          {displayNumber}
        </Text>
      </Tooltip>
    </Flex>
  );
};

export default TopicCoverageList;
