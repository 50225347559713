import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  useEventListener,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Avatar, HighlightText, LoadingIndicator } from "../../../components";
import useInputTrigger from "../../../hooks/useInputTrigger";
import { ShareUserFragment } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";

interface MentionMenuProps {
  inputRef: React.MutableRefObject<HTMLElement | null>;
  shareableUsers?: ShareUserFragment[];
  loading: boolean;
  zIndex?: number | string;
  onToggle?(isOpen: boolean): void;
}

const MentionMenu: React.FC<MentionMenuProps> = ({
  inputRef,
  shareableUsers = [],
  loading,
  zIndex,
  onToggle,
}) => {
  const [state, replace] = useInputTrigger(inputRef, "@");
  const [focusIndex, setFocusIndex] = useState(0);
  const triggerValue = state.value?.substring(1) ?? "";
  const currentUser = useCurrentUser();

  const filteredUsers = useMemo(() => {
    if (!triggerValue) {
      return shareableUsers;
    }
    const re = new RegExp(
      `(${triggerValue.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")})`,
      "gi"
    );
    return shareableUsers.filter(
      (user) =>
        currentUser.email !== user.email &&
        (re.exec(user.fullName) || re.exec(user.email))
    );
  }, [triggerValue, shareableUsers]);
  const sortedUsers = useMemo(
    () =>
      [...(filteredUsers ?? [])].sort((a, b) => {
        return a.email < b.email ? -1 : 1;
      }),
    [filteredUsers]
  );
  const displayedUsers = sortedUsers.slice(0, 5);
  const doReplace = (replacementText: string): void => {
    replace(replacementText);
    setFocusIndex(0);
  };

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (
        !state.isTriggered ||
        !displayedUsers.length ||
        inputRef.current !== document.activeElement
      ) {
        return;
      }
      const menuLength = displayedUsers.length;
      switch (event.key) {
        case "Down":
        case "ArrowDown":
          event.preventDefault();
          if (focusIndex < menuLength - 1) {
            setFocusIndex(focusIndex + 1);
          } else {
            setFocusIndex(0);
          }
          break;
        case "Up":
        case "ArrowUp":
          event.preventDefault();
          if (focusIndex > 0) {
            setFocusIndex(focusIndex - 1);
          } else {
            setFocusIndex(menuLength - 1);
          }
          break;
        case "Enter":
        case "Tab":
          if (focusIndex >= displayedUsers.length) {
            return;
          }
          event.preventDefault();
          doReplace(`@${displayedUsers[focusIndex].email} `);
          break;
        case "Esc":
        case "Escape":
          event.preventDefault();
          doReplace(`@${triggerValue}`);
          break;
        default:
      }
    },
    [state.isTriggered, triggerValue, displayedUsers, focusIndex]
  );
  useEventListener("keydown", handleKeydown);

  const prevDisplayedUsersRef = useRef<ShareUserFragment[]>();
  useEffect(() => {
    const resetFocus =
      !displayedUsers.length ||
      !displayedUsers.every((u) => prevDisplayedUsersRef.current?.includes(u));
    if (resetFocus) {
      setFocusIndex(0);
      prevDisplayedUsersRef.current = [...displayedUsers];
    }
  }, [displayedUsers]);

  const isOpen = state.isTriggered && !!filteredUsers?.length;
  useEffect(() => onToggle?.(isOpen), [isOpen]);

  return (
    <Menu isOpen={isOpen} isLazy>
      <Portal>
        {/* workaround for https://github.com/chakra-ui/chakra-ui/issues/3814 */}
        <Box position="fixed" top="0px" zIndex={zIndex}>
          <MenuButton
            position="fixed"
            visibility="hidden"
            top={state.caretCoordinates?.y}
            left={state.caretCoordinates?.x}
          />
          <MenuList>
            {loading && <LoadingIndicator size="sm" />}
            {displayedUsers.map((user, index) => (
              <Flex
                key={user.id}
                alignItems="center"
                cursor="pointer"
                px="2"
                py="2px"
                _hover={{ bg: focusIndex === index ? "blue.100" : "blue.50" }}
                bg={focusIndex === index ? "blue.100" : undefined}
                onMouseDown={(e) => {
                  e.preventDefault();
                  doReplace(`@${user.email} `);
                }}
              >
                <Avatar user={user} />
                <Box ml="2" maxW="150px">
                  <HighlightText
                    text={user.fullName}
                    keywords={[triggerValue]}
                    fontSize="xs"
                    overflowX="hidden"
                    whiteSpace="nowrap"
                  />
                  <HighlightText
                    text={user.email}
                    keywords={[triggerValue]}
                    fontSize="10px"
                    overflowX="hidden"
                    whiteSpace="nowrap"
                  />
                </Box>
              </Flex>
            ))}
          </MenuList>
        </Box>
      </Portal>
    </Menu>
  );
};

export default MentionMenu;
