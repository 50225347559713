import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {} from "react-icons/io";
import ReactSelect from "react-select";

import { useToast } from "../../../../components";
import { TimezoneSelect } from "../../../components";
import {
  Ats,
  useOrgUsersOnlyQuery,
  useUpdateOrganizationRedactionMutation,
} from "../../../graphql";

interface FormValues {
  enableRetentionSinceCandidateHired?: boolean;
  retentionDaysSinceCandidateHired?: number;
  enableRetentionSinceCandidateRejected?: boolean;
  retentionDaysSinceCandidateRejected?: number;
  enableRetentionSinceLastInterview: boolean;
  retentionDaysSinceLastInterview: number;
  enableRedactionForGreenhouse?: boolean;
  enableRedactionForLever?: boolean;
  enableRedactionForSmartrecruiters?: boolean;
  enableRedactionForAshby?: boolean;
  enableRetentionSinceInterview: boolean;
  retentionDaysSinceInterview: number;
  retentionNotificationDays: string[];
  retentionEmailSendTimezone: string;
  retentionEmailList: {
    label: string;
    value: string;
  }[];
  retentionEmailSendTime: string;
  organizationId: string;
  preventRedactionForTraining: boolean;
}

interface DataRetentionInputsProps {
  organization: {
    ats?: Ats | null;
    enableRetentionSinceCandidateHired: boolean;
    retentionDaysSinceCandidateHired: number;
    enableRetentionSinceCandidateRejected: boolean;
    retentionDaysSinceCandidateRejected: number;
    enableRetentionSinceLastInterview: boolean;
    retentionDaysSinceLastInterview: number;
    enableRetentionSinceInterview: boolean;
    retentionDaysSinceInterview: number;
    enableRedactionForGreenhouse: boolean;
    enableRedactionForLever: boolean;
    enableRedactionForSmartrecruiters: boolean;
    enableRedactionForAshby: boolean;
    retentionNotificationDays?: (string | null)[] | null;
    organizationRetentionUser: {
      userId: string;
    }[];
    retentionEmailSendTime: string;
    retentionEmailSendTimezone: string;
    preventRedactionForTraining: boolean;
  };
}

const DataRetentionInputsPage: React.FC<DataRetentionInputsProps> = ({
  organization,
}) => {
  const toast = useToast();
  const [notificationDays, setNotificationDays] = useState(
    organization?.retentionNotificationDays ?? []
  );
  const { data: orgUsers, loading: orgUsersLoading } = useOrgUsersOnlyQuery();
  const [updateSettings, { loading: loadingUpdateSettings }] =
    useUpdateOrganizationRedactionMutation({
      onError: (err) => {
        toast({
          title: "Error",
          description: `Failed to setup : ${err.message}`,
          status: "error",
        });
      },
      onCompleted: (data) => {
        toast({
          title: "Success",
          description: `Retention values successfully updated.`,
          status: "success",
        });
      },
    });

  const defaultTime = useMemo(() => {
    const date = new Date();
    date.setHours(
      parseInt(organization?.retentionEmailSendTime?.split(":")[0])
    );
    date.setMinutes(
      parseInt(organization?.retentionEmailSendTime?.split(":")[1])
    );
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }, [organization?.retentionEmailSendTime]);
  const { register, handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: {
      enableRetentionSinceCandidateHired:
        organization.enableRetentionSinceCandidateHired,
      retentionDaysSinceCandidateHired:
        organization.retentionDaysSinceCandidateHired,
      enableRetentionSinceCandidateRejected:
        organization.enableRetentionSinceCandidateRejected,
      retentionDaysSinceCandidateRejected:
        organization.retentionDaysSinceCandidateRejected,
      enableRetentionSinceLastInterview:
        organization.enableRetentionSinceLastInterview,
      retentionDaysSinceLastInterview:
        organization.retentionDaysSinceLastInterview,
      enableRedactionForGreenhouse: organization.enableRedactionForGreenhouse,
      enableRedactionForLever: organization.enableRedactionForLever,
      enableRedactionForSmartrecruiters:
        organization.enableRedactionForSmartrecruiters,
      enableRedactionForAshby: organization.enableRedactionForAshby,
      enableRetentionSinceInterview: organization.enableRetentionSinceInterview,
      retentionDaysSinceInterview: organization.retentionDaysSinceInterview,
      retentionEmailSendTime: defaultTime,
      preventRedactionForTraining: organization.preventRedactionForTraining,
      // To be loaded async and set with reset
      retentionEmailList: [],
      retentionEmailSendTimezone: organization.retentionEmailSendTimezone,
    },
  });

  const userOptions = useMemo(() => {
    const filtered = (orgUsers?.currentUser?.organization?.users || []).filter(
      (u) => u.userRole?.name === "SITE_ADMIN"
    );
    filtered.sort((ua, ub) => {
      if (ua.fullName < ub.fullName) return -1;
      if (ua.fullName > ub.fullName) return 1;
      return 0;
    });
    return filtered.map((user) => {
      return {
        label: `${user.fullName} - ${user.email}`,
        value: user.id,
      };
    });
  }, [orgUsers]);
  // This sets selectedUsers after initial data is loaded
  useEffect(() => {
    const retentionEmailList = organization?.organizationRetentionUser || [];
    const selectedUsers = userOptions.filter((option) => {
      return retentionEmailList.some((x) => x.userId === option.value);
    });
    reset({ retentionEmailList: selectedUsers });
  }, [userOptions, organization]);

  const times = useMemo((): string[] => {
    const dt = new Date(1970, 0, 1);
    const step = 15;
    const rc = [];
    while (dt.getDate() === 1) {
      rc.push(
        dt.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
      );
      dt.setMinutes(dt.getMinutes() + step);
    }
    return rc;
  }, []);

  const onSubmit = handleSubmit((formValues) => {
    const selectedUserIds = formValues.retentionEmailList.map((x) => x.value);

    updateSettings({
      variables: {
        enableRetentionSinceCandidateHired:
          formValues.enableRetentionSinceCandidateHired,
        retentionDaysSinceCandidateHired:
          formValues.retentionDaysSinceCandidateHired,
        enableRetentionSinceCandidateRejected:
          formValues.enableRetentionSinceCandidateRejected,
        retentionDaysSinceCandidateRejected:
          formValues.retentionDaysSinceCandidateRejected,
        enableRetentionSinceLastInterview:
          formValues.enableRetentionSinceLastInterview,
        retentionDaysSinceLastInterview:
          formValues.retentionDaysSinceLastInterview,
        enableRedactionForGreenhouse: formValues.enableRedactionForGreenhouse,
        enableRedactionForLever: formValues.enableRedactionForLever,
        enableRedactionForSmartrecruiters:
          formValues.enableRedactionForSmartrecruiters,
        enableRedactionForAshby: formValues.enableRedactionForAshby,
        enableRetentionSinceInterview: formValues.enableRetentionSinceInterview,
        retentionDaysSinceInterview: formValues.retentionDaysSinceInterview,
        retentionNotificationDays: notificationDays,
        retentionEmailList: selectedUserIds,
        retentionEmailSendTime: formValues.retentionEmailSendTime,
        retentionEmailSendTimezone: formValues.retentionEmailSendTimezone,
        preventRedactionForTraining: formValues.preventRedactionForTraining,
      },
    });
  });

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let updatedList = [...notificationDays];
    if (event.target.checked) {
      updatedList = [...notificationDays, event.target.value];
    } else {
      updatedList.splice(notificationDays.indexOf(event.target.value), 1);
    }
    setNotificationDays(updatedList);
  };

  const loading = orgUsersLoading || loadingUpdateSettings;
  const atsList = [Ats.Greenhouse, Ats.Lever, Ats.Smartrecruiters];

  return (
    <form onSubmit={onSubmit}>
      <Heading as="h3" size="sm" mt={8} mb={4}>
        Data Retention Settings
      </Heading>
      <Text>
        Notify me about candidates and interviews that are scheduled for
        deletion.
      </Text>
      {organization.ats && atsList.includes(organization.ats) && (
        <FormControl mt={2}>
          <HStack>
            <Checkbox
              {...register("enableRetentionSinceCandidateHired")}
              defaultChecked={organization.enableRetentionSinceCandidateHired}
            />
            <Text>
              If the candidate was marked &quot;hired&quot; in the ATS
            </Text>
            <NumberInput
              max={3000}
              min={14}
              defaultValue={365}
              width="100px"
              size="sm"
            >
              <NumberInputField
                {...register("retentionDaysSinceCandidateHired", {
                  valueAsNumber: true,
                })}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text ml={1}>days ago.</Text>
          </HStack>
        </FormControl>
      )}
      {organization.ats && atsList.includes(organization.ats) && (
        <FormControl mt={2}>
          <HStack>
            <Checkbox
              {...register("enableRetentionSinceCandidateRejected")}
              defaultChecked={
                organization.enableRetentionSinceCandidateRejected
              }
            />
            <Text>
              If the candidate was marked &quot;rejected&quot; in the ATS
            </Text>
            <NumberInput
              max={3000}
              min={14}
              defaultValue={365}
              width="100px"
              size="sm"
            >
              <NumberInputField
                {...register("retentionDaysSinceCandidateRejected", {
                  valueAsNumber: true,
                })}
              />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Text ml={1}>days ago.</Text>
          </HStack>
        </FormControl>
      )}
      <FormControl mt={2}>
        <HStack>
          <Checkbox
            {...register("enableRetentionSinceLastInterview")}
            defaultChecked={organization.enableRetentionSinceLastInterview}
          />
          <Text>If the candidate&apos;s last interview was</Text>
          <NumberInput
            max={3000}
            min={14}
            defaultValue={365}
            width="100px"
            size="sm"
          >
            <NumberInputField
              {...register("retentionDaysSinceLastInterview", {
                valueAsNumber: true,
              })}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Text ml={1}>days ago.</Text>
        </HStack>
      </FormControl>
      {organization.ats === Ats.Greenhouse && (
        <FormControl mt={2}>
          <HStack>
            <Checkbox
              {...register("enableRedactionForGreenhouse")}
              defaultChecked={organization.enableRedactionForGreenhouse}
            />
            <Text>
              If the candidate&apos;s data is anonymized in Greenhouse.
            </Text>
          </HStack>
        </FormControl>
      )}
      {organization.ats === Ats.Lever && (
        <FormControl mt={2}>
          <HStack>
            <Checkbox
              {...register("enableRedactionForLever")}
              defaultChecked={organization.enableRedactionForLever}
            />
            <Text>If the candidate&apos;s data is anonymized in Lever.</Text>
          </HStack>
        </FormControl>
      )}
      {organization.ats === Ats.Smartrecruiters && (
        <FormControl mt={2}>
          <HStack>
            <Checkbox
              {...register("enableRedactionForSmartrecruiters")}
              defaultChecked={organization.enableRedactionForSmartrecruiters}
            />
            <Text>
              If the candidate&apos;s data is deleted in SmartRecruiters.
            </Text>
          </HStack>
        </FormControl>
      )}
      {organization.ats === Ats.Ashby && (
        <FormControl mt={2}>
          <HStack>
            <Checkbox
              {...register("enableRedactionForAshby")}
              defaultChecked={organization.enableRedactionForAshby}
            />
            <Text>If the candidate&apos;s data is deleted in Ashby.</Text>
          </HStack>
        </FormControl>
      )}
      <FormControl mt={2}>
        <HStack>
          <Checkbox
            {...register("enableRetentionSinceInterview")}
            defaultChecked={organization.enableRetentionSinceInterview}
          />
          <Text>If the interview took place</Text>
          <NumberInput
            max={3000}
            min={14}
            defaultValue={365}
            width="100px"
            size="sm"
          >
            <NumberInputField
              {...register("retentionDaysSinceInterview", {
                valueAsNumber: true,
              })}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Text ml={1}>days ago.</Text>
        </HStack>
      </FormControl>
      <Heading as="h3" size="sm" mt={8} mb={4}>
        Training Settings
      </Heading>
      <FormControl mt={2}>
        <HStack>
          <Checkbox
            {...register("preventRedactionForTraining")}
            defaultChecked={organization.preventRedactionForTraining}
          />
          <Text>
            Prevent deletion of interviews and candidates that are in training
            programs.
          </Text>
        </HStack>
      </FormControl>
      <Heading as="h3" size="sm" mt={8} mb={4}>
        Notification Settings
      </Heading>
      <Text w="100%">
        Send the selected users below a notification email if there are
        candidates or interviews to delete.
      </Text>
      <FormControl width="300px" mt={2}>
        <FormLabel>Send email on:</FormLabel>
        <Stack spacing={[1, 2]} direction={["column", "row"]}>
          <Checkbox
            onChange={handleCheck}
            defaultChecked={notificationDays.indexOf("0") >= 0}
            value="0"
          >
            Sun
          </Checkbox>
          <Checkbox
            onChange={handleCheck}
            defaultChecked={notificationDays.indexOf("1") >= 0}
            value="1"
          >
            Mon
          </Checkbox>
          <Checkbox
            onChange={handleCheck}
            defaultChecked={notificationDays.indexOf("2") >= 0}
            value="2"
          >
            Tue
          </Checkbox>
          <Checkbox
            onChange={handleCheck}
            defaultChecked={notificationDays.indexOf("3") >= 0}
            value="3"
          >
            Wed
          </Checkbox>
          <Checkbox
            onChange={handleCheck}
            defaultChecked={notificationDays.indexOf("4") >= 0}
            value="4"
          >
            Thu
          </Checkbox>
          <Checkbox
            onChange={handleCheck}
            defaultChecked={notificationDays.indexOf("5") >= 0}
            value="5"
          >
            Fri
          </Checkbox>
          <Checkbox
            onChange={handleCheck}
            defaultChecked={notificationDays.indexOf("6") >= 0}
            value="6"
          >
            Sat
          </Checkbox>
        </Stack>
      </FormControl>
      <FormControl width="450px" mt={2}>
        <FormLabel>Recipients:</FormLabel>
        <Controller
          control={control}
          name="retentionEmailList"
          render={({ field: { onChange, value } }) => (
            <ReactSelect
              data-testid="exclusion-select"
              isClearable
              isMulti
              isLoading={orgUsersLoading}
              placeholder="Add users to send email"
              value={value}
              options={userOptions}
              onChange={onChange}
            />
          )}
        />
      </FormControl>
      <FormControl width="300px" mt={2}>
        <FormLabel>Time:</FormLabel>
        <Controller
          control={control}
          name="retentionEmailSendTime"
          defaultValue={defaultTime}
          render={({ field: { onChange, value, ref } }) => (
            <Select
              name="retentionEmailSendTime"
              ref={ref}
              value={value}
              onChange={(v) => {
                onChange(v.target.value);
              }}
            >
              {times.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl width="300px" mt={2}>
        <FormLabel>Timezone:</FormLabel>
        <Controller
          control={control}
          defaultValue={organization.retentionEmailSendTimezone}
          name="retentionEmailSendTimezone"
          render={({ field: { onChange, value } }) => (
            <TimezoneSelect timezone={value} onChange={onChange} />
          )}
        />
      </FormControl>
      {(loading && <Spinner mt={4} />) || (
        <Button mt={4} type="submit" variant="solid">
          Save
        </Button>
      )}
    </form>
  );
};

export default DataRetentionInputsPage;
