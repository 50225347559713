import { useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";

import { TemplateSections, TemplateSubsections } from "./types";

export const useUpdateSections = (
  sections?: TemplateSections
): {
  currentSections: TemplateSections;
  handleMoveSection: (result: DropResult) => void;
  handleMoveSubsection: (result: DropResult, sectionId: string) => void;
  handleTitleChange: (
    newTitle: string,
    sectionId: string,
    subsectionId?: string
  ) => void;
  handlePromptChange: (
    newPrompt: string,
    sectionId: string,
    subsectionId?: string
  ) => void;
  addSection: () => void;
  addSubsection: (sectionId: string) => void;
  deleteSection: (sectionId: string) => void;
  deleteSubsection: (sectionId: string, subsectionId: string) => void;
} => {
  const [currentSections, setCurrentSections] =
    useState<TemplateSections>(undefined);

  useEffect(() => {
    setCurrentSections(sections);
  }, [sections]);

  const handleMoveSection = (result: DropResult): void => {
    setCurrentSections((prevSections) => {
      if (!prevSections) return undefined;
      if (!result.destination) return prevSections;

      const movedSection = prevSections.find(
        (item) => item.id === result.draggableId
      );
      if (!movedSection) return prevSections;
      if (movedSection.position === result.destination.index)
        return prevSections;

      const newSections: TemplateSections = [];
      for (let i = 0; i < prevSections.length; i += 1) {
        // eslint-disable-next-line no-continue
        if (prevSections[i].id === result.draggableId) continue;
        // Necessary to handle ordering of elements when this loop vists
        // them relative to the starting point of the element
        if (result.destination.index < result.source.index) {
          // Destination is earlier, so we should prioritize addinge new element
          if (i === result.destination.index) {
            newSections.push({
              ...movedSection,
              position: newSections.length,
            });
          }
          newSections.push({
            ...prevSections[i],
            position: newSections.length,
          });
        } else {
          // Destination is after, so we should priorize the previous section
          newSections.push({
            ...prevSections[i],
            position: newSections.length,
          });
          if (i === result.destination.index) {
            newSections.push({ ...movedSection, position: newSections.length });
          }
        }
      }
      return newSections;
    });
  };

  const handleMoveSubsection = (
    result: DropResult,
    sectionId: string
  ): void => {
    setCurrentSections((prevSections) => {
      if (!prevSections) return undefined;
      if (!result.destination) return prevSections;

      const parentSection = prevSections.find((item) => item.id === sectionId);
      if (!parentSection) return prevSections;

      const prevSubsections = parentSection.subsections;
      const movedSubection = prevSubsections.find(
        (item) => item.id === result.draggableId
      );
      if (!movedSubection) return prevSections;
      if (movedSubection.position === result.destination.index)
        return prevSections;

      const newSubsections: TemplateSubsections = [];
      for (let i = 0; i < prevSubsections.length; i += 1) {
        // eslint-disable-next-line no-continue
        if (prevSubsections[i].id === result.draggableId) continue;
        // Necessary to handle ordering of elements when this loop vists
        // them relative to the starting point of the element
        if (result.destination.index < result.source.index) {
          // Destination is earlier, so we should prioritize addinge new element
          if (i === result.destination.index) {
            newSubsections.push({
              ...movedSubection,
              position: newSubsections.length,
            });
          }
          newSubsections.push({
            ...prevSubsections[i],
            position: newSubsections.length,
          });
        } else {
          // Destination is after, so we should priorize the previous section
          newSubsections.push({
            ...prevSubsections[i],
            position: newSubsections.length,
          });
          if (i === result.destination.index) {
            newSubsections.push({
              ...movedSubection,
              position: newSubsections.length,
            });
          }
        }
      }
      return prevSections.map((item) => {
        if (item.id === sectionId) {
          return { ...item, subsections: newSubsections };
        }
        return item;
      });
    });
  };

  const handleTitleChange = (
    newTitle: string,
    sectionId: string,
    subsectionId?: string
  ): void => {
    setCurrentSections((prevSections) => {
      return prevSections?.map((item) => {
        if (item.id === sectionId) {
          // This overloads the logic and changes the subsection title only
          if (subsectionId) {
            const newSubsections = item.subsections.map((subitem) => {
              if (subitem.id === subsectionId) {
                return { ...subitem, title: newTitle };
              }
              return subitem;
            });
            return { ...item, subsections: newSubsections };
          }

          // Only changes section title
          return { ...item, title: newTitle };
        }
        return item;
      });
    });
  };

  const handlePromptChange = (
    newPrompt: string,
    sectionId: string,
    subsectionId?: string
  ): void => {
    setCurrentSections((prevSections) => {
      return prevSections?.map((item) => {
        if (item.id === sectionId) {
          // This overloads the logic and changes the subsection prompt only
          if (subsectionId) {
            const newSubsections = item.subsections.map((subitem) => {
              if (subitem.id === subsectionId) {
                return { ...subitem, prompt: [newPrompt] };
              }
              return subitem;
            });
            return { ...item, subsections: newSubsections };
          }

          // Only changes section prompt
          return { ...item, prompt: [newPrompt] };
        }
        return item;
      });
    });
  };

  const addSection = (): void => {
    setCurrentSections((prevSections) => {
      const oldSections: TemplateSections =
        prevSections?.map((item) => item) || [];
      let position = 0;
      if (oldSections && oldSections.length > 0) {
        position = oldSections[oldSections.length - 1].position;
        position += 1;
      }
      const newSection = {
        id: `${crypto.randomUUID()}`,
        position,
        title: "",
        prompt: [],
        subsections: [
          {
            id: `${crypto.randomUUID()}`,
            position: 0,
            title: "",
            prompt: [],
          },
        ],
      };
      oldSections.push(newSection);
      return oldSections;
    });
  };

  const addSubsection = (sectionId: string): void => {
    setCurrentSections((prevSections) => {
      return prevSections?.map((item) => {
        if (item.id === sectionId) {
          const newSubsection = {
            id: `${crypto.randomUUID()}`,
            position: 0,
            title: "",
            prompt: [],
          };
          return { ...item, subsections: [...item.subsections, newSubsection] };
        }
        return { ...item };
      });
    });
  };

  const deleteSection = (sectionId: string): void => {
    setCurrentSections((prevSections) => {
      return prevSections?.filter((item) => item.id !== sectionId);
    });
  };

  const deleteSubsection = (sectionId: string, subsectionId: string): void => {
    setCurrentSections((prevSections) => {
      return prevSections?.map((item) => {
        if (item.id === sectionId) {
          return {
            ...item,
            subsections: item.subsections.filter(
              (subitem) => subitem.id !== subsectionId
            ),
          };
        }
        return { ...item };
      });
    });
  };

  return {
    currentSections,
    handleMoveSection,
    handleMoveSubsection,
    handleTitleChange,
    handlePromptChange,
    addSection,
    addSubsection,
    deleteSection,
    deleteSubsection,
  };
};
