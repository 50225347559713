import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Textarea,
} from "@chakra-ui/react";
import React, { FC } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi";

import DragHandleDots from "../../../../components/Icons/DragHandleDots";
import { CandidateSummaryTemplateFragment } from "../../../graphql";

type TemplateSubsections =
  | CandidateSummaryTemplateFragment["sections"][0]["subsections"]
  | undefined;

type EditTemplateSubsectionProps = {
  subsections: TemplateSubsections;
  handleMoveSubsection: (result: DropResult) => void;
  handleTitleChange: (newTitle: string, subsectionId: string) => void;
  handlePromptChange: (newPrompt: string, subsectionId: string) => void;
  addSubsection: () => void;
  deleteSubsection: (subsectionId: string) => void;
};

const EditTemplateSubsection: FC<EditTemplateSubsectionProps> = ({
  subsections,
  handleMoveSubsection,
  handleTitleChange,
  handlePromptChange,
  addSubsection,
  deleteSubsection,
}) => {
  return (
    <Box>
      <DragDropContext
        onDragEnd={(result) => {
          handleMoveSubsection(result);
        }}
      >
        <Droppable droppableId="droppable-subsections">
          {(provided) => (
            // eslint-disable-next-line @typescript-eslint/unbound-method
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {subsections?.map((child, index) => (
                <Draggable
                  key={child.id}
                  draggableId={child.id}
                  index={index}
                  isDragDisabled={false}
                >
                  {(provided) => (
                    <Flex
                      key={child.id}
                      alignItems="flex-start"
                      backgroundColor="white"
                      pt={4}
                      pb={5}
                      pr={4}
                      border="1px solid"
                      borderColor="gray.200"
                      borderRadius="8px"
                      mt={4}
                      // eslint-disable-next-line @typescript-eslint/unbound-method
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Flex
                        {...provided.dragHandleProps}
                        height={8}
                        width={8}
                        mr={1}
                        ml={2}
                        alignItems="center"
                        justifyContent="center"
                        data-testid={`drag-handle-${index}`}
                      >
                        <DragHandleDots width={5} height={5} />
                      </Flex>
                      <Flex direction="column" width="100%" gap={2}>
                        <Input
                          value={child.title}
                          placeholder="Subtopic title"
                          size="sm"
                          onChange={(event) => {
                            handleTitleChange(event.target.value, child.id);
                          }}
                        />
                        <Textarea
                          value={child.prompt.join(" ")}
                          placeholder="Reference examples for the subtopic categorization"
                          onChange={(event) => {
                            handlePromptChange(event.target.value, child.id);
                          }}
                          size="sm"
                        />
                      </Flex>
                      <IconButton
                        variant="icon"
                        aria-label="Delete Question"
                        color="red.400"
                        backgroundColor="transparent"
                        icon={<HiOutlineTrash size={20} />}
                        ml={6}
                        onClick={() => deleteSubsection(child.id)}
                      />
                    </Flex>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        size="sm"
        mt={5}
        justifyContent="flex-start"
        variant="ghost"
        fontSize="sm"
        fontWeight="500"
        leftIcon={<HiOutlinePlus />}
        onClick={() => addSubsection()}
      >
        Add subtopic
      </Button>
    </Box>
  );
};

export default EditTemplateSubsection;
