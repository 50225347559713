import { NetworkStatus } from "@apollo/client";
import { BoxProps } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { useToast } from "../../../components";
import {
  CandidateAlertFeedFilter,
  CandidateAlertFeedListItemFragment,
  useCandidateAlertFeedLazyQuery,
  useMarkCandidateAlertFeedReadMutation,
} from "../../graphql";
import { CandidateAlertList } from "./CandidateAlertList";
import { useCandidateAlertDisabled } from "./useCandidateAlertDisabled";

const PAGE_LIMIT = 10;

const noAlertHelperTextMap = {
  [CandidateAlertFeedFilter.All]:
    "Candidate Alerts notify you of potential closing risks and action items related to your candidates",
  [CandidateAlertFeedFilter.Unread]:
    "There are no unread alerts for your canddiates.",
  [CandidateAlertFeedFilter.Read]:
    "You have not read any alerts for your candidates.",
  [CandidateAlertFeedFilter.MyInterviews]:
    "There are no alerts for your interviews",
  [CandidateAlertFeedFilter.ClosingRisk]:
    "There are no alerts for closing risks",
  [CandidateAlertFeedFilter.ActionItem]: "There are no alerts for action items",
};

type CandidateAlertFeedProps = {
  alertFilters?: CandidateAlertFeedFilter[];
  /** Counter to be incremented when "Mark all as read" is clicked */
  markAllReadClickCount?: number;
  onAlertsFetched?(alerts: CandidateAlertFeedListItemFragment[]): void;
  itemStyles?: BoxProps;
};

const CandidateAlertFeed: React.FC<CandidateAlertFeedProps> = ({
  alertFilters,
  markAllReadClickCount,
  onAlertsFetched,
  itemStyles,
}) => {
  const toast = useToast();
  const [noAlertHelperText, setNoAlertHelperText] = useState(
    noAlertHelperTextMap[CandidateAlertFeedFilter.All]
  );

  const candidateAlertsDisabled = useCandidateAlertDisabled();

  const [getAlertFeed, { data, loading, fetchMore, networkStatus, called }] =
    useCandidateAlertFeedLazyQuery({
      notifyOnNetworkStatusChange: true,
      onError: () => {
        toast({
          title: "Error",
          description: "Unable to load candidate alerts",
          status: "error",
        });
      },
    });
  const results = data?.candidateAlertFeed.results || [];

  const [markRead] = useMarkCandidateAlertFeedReadMutation({
    update(cache, { data }) {
      const ids = data?.markCandidateAlertFeedRead?.markedIds;
      const readAt = data?.markCandidateAlertFeedRead?.readAt;
      if (ids && readAt) {
        ids.forEach((id) => {
          cache.modify({
            id: cache.identify({ __typename: "CandidateAlertFeed", id }),
            fields: {
              readAt() {
                return readAt;
              },
            },
          });
        });
      }
    },
  });

  const handleAlertRead = (candidateAlertFeedIds?: string[]): void => {
    markRead({
      variables: {
        candidateAlertFeedIds,
        alertFilters,
      },
    });
  };

  useEffect(() => {
    if (markAllReadClickCount) {
      handleAlertRead();
    }
  }, [markAllReadClickCount]);

  /**
   * Executes at the beginning
   */
  useEffect(() => {
    if (!candidateAlertsDisabled) {
      if (alertFilters) {
        if (alertFilters.length === 1) {
          setNoAlertHelperText(noAlertHelperTextMap[alertFilters[0]]);
        } else {
          setNoAlertHelperText("No alerts match the selected filters");
        }
      }
      getAlertFeed({
        fetchPolicy: "network-only",
        variables: {
          pagination: {
            limit: PAGE_LIMIT,
          },
          alertFilters,
        },
      });
    }
  }, [alertFilters]);

  /**
   * Executed when the user loads a new page of results
   */
  const onLoadMore = (): void => {
    fetchMore({
      variables: {
        pagination: {
          limit: Number(results.length) + PAGE_LIMIT,
        },
      },
    });
  };

  // Need to rerun when user clicks on alert to update read dot on menu
  useEffect(() => {
    if (called && !loading) {
      onAlertsFetched?.(results);
    }
  }, [results]);

  return (
    <CandidateAlertList
      alerts={results}
      onLoadMore={onLoadMore}
      loading={
        loading &&
        networkStatus !== NetworkStatus.refetch &&
        networkStatus !== NetworkStatus.ready
      }
      showLoadMore={data?.candidateAlertFeed.pageInfo?.hasNextPage || false}
      handleAlertRead={handleAlertRead}
      noAlertHelperText={noAlertHelperText}
      itemStyles={itemStyles}
    />
  );
};

export default CandidateAlertFeed;
