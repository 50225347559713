import { Modal, ModalOverlay } from "@chakra-ui/react";
import React from "react";

import { CallGuideListItemFragment } from "../../graphql";
import useAddCallGuide from "../../graphql/hooks/CallGuide/useAddCallGuide";
import GuideForm from "../GuideForm";

interface AddCallGuideModalProps {
  defaultPositionId?: string;
  isOpen: boolean;
  onSave: (callguide: CallGuideListItemFragment) => void;
  onClose: () => void;
}

const AddCallGuideModal: React.FC<AddCallGuideModalProps> = ({
  defaultPositionId,
  isOpen,
  onSave,
  onClose,
}) => {
  const [addCallGuide, { loading: addLoading, error: addError }] =
    useAddCallGuide({
      onCompleted: (data) => {
        if (data?.addCallGuide) {
          onSave(data?.addCallGuide.callGuide);
        }
      },
    });

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay>
        <GuideForm
          name=""
          positionId={defaultPositionId}
          error={addError}
          isLoading={addLoading}
          onSubmit={(formData) => addCallGuide({ variables: formData })}
          onCancel={onClose}
          title="Add Guide"
        />
      </ModalOverlay>
    </Modal>
  );
};

export default AddCallGuideModal;
