import { Box, Flex, IconButton, IconButtonProps, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import {
  HiOutlineThumbDown,
  HiOutlineThumbUp,
  HiThumbDown,
  HiThumbUp,
} from "react-icons/hi";

import { useToast } from "../../../components";
import {
  CandidateAlertAccuracyFeedback,
  useUpdateCandidateAlertAccuracyFeedbackMutation,
} from "../../graphql";

interface RatingButtonProps extends IconButtonProps {
  defaultIcon: React.ReactElement;
  hoverIcon: React.ReactElement;
  isSubmitted: boolean;
}

const RatingButton: React.FC<RatingButtonProps> = ({
  defaultIcon,
  hoverIcon,
  isSubmitted,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const displayIcon = isSubmitted
    ? hoverIcon
    : isHovered
    ? hoverIcon
    : defaultIcon;
  return (
    <IconButton
      variant="unstyled"
      color="blue.600"
      align="center"
      justify="center"
      display="flex"
      size="md"
      icon={displayIcon}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    />
  );
};

export const CandidateAlertFeedback: React.FC<{
  alertFeedId: string;
  accuracyFeedback: CandidateAlertAccuracyFeedback | null | undefined;
}> = ({ alertFeedId, accuracyFeedback }) => {
  const toast = useToast();
  const [updateFeedback] = useUpdateCandidateAlertAccuracyFeedbackMutation({
    update(cache, { data }) {
      const ids = data?.updateCandidateAlertAccuracyFeedback?.updatedIds;
      const feedback = data?.updateCandidateAlertAccuracyFeedback?.feedback;
      if (ids && feedback) {
        ids.forEach((id) => {
          cache.modify({
            id: cache.identify({ __typename: "CandidateAlertFeed", id }),
            fields: {
              accuracyFeedback() {
                return feedback;
              },
            },
          });
        });
      }
    },
    onError: () => {
      toast({
        title: "Error",
        description: `Failed to save your feedback`,
        status: "error",
      });
    },
    onCompleted: (data) => {
      if (data.updateCandidateAlertAccuracyFeedback) {
        toast({
          title: "Success",
          description: "Alert accuracy feedback saved",
          status: "success",
        });
      }
    },
  });

  return (
    <Box borderTop="1px solid" borderColor="gray.200">
      <Flex align="center" justify="space-between">
        <Text color="gray.600" fontSize="xs">
          {`Is this alert helpful?`}
        </Text>
        <Flex direction="row">
          <RatingButton
            aria-label="Thumbs up"
            defaultIcon={<HiOutlineThumbUp size="16px" />}
            hoverIcon={<HiThumbUp size="16px" />}
            isSubmitted={
              accuracyFeedback === CandidateAlertAccuracyFeedback.Good
            }
            onClick={() => {
              if (accuracyFeedback === CandidateAlertAccuracyFeedback.Good)
                return;
              updateFeedback({
                variables: {
                  candidateAlertFeedIds: [alertFeedId],
                  feedback: CandidateAlertAccuracyFeedback.Good,
                },
              });
            }}
          />
          <RatingButton
            aria-label="Thumbs down"
            defaultIcon={<HiOutlineThumbDown size="16px" />}
            hoverIcon={<HiThumbDown size="16px" />}
            isSubmitted={
              accuracyFeedback === CandidateAlertAccuracyFeedback.Bad
            }
            onClick={() => {
              if (accuracyFeedback === CandidateAlertAccuracyFeedback.Bad)
                return;
              updateFeedback({
                variables: {
                  candidateAlertFeedIds: [alertFeedId],
                  feedback: CandidateAlertAccuracyFeedback.Bad,
                },
              });
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
