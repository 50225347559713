import { formatISODate } from "../../../utils/datetime";
import { paginate, PaginatedResult } from "../../components/Pagination/utils";
import {
  AnalyticsDimension,
  AnalyticsReportQuery,
  MetricName,
  ReportConfig,
  useAnalyticsReportQuery,
  useAnalyticsReportTrendsQuery,
} from "../../graphql";
import useFeatureFlag from "../../graphql/hooks/useFeatureFlag";
import {
  AnalyticsConfig,
  CommonQueryVariables,
  LineChartDataPoint,
} from "./types";
import { getAverageScore, getLineChartData, sortTableData } from "./utils";

export type ReportData = {
  report?: AnalyticsReportQuery["qualityReport"];
  pagination: PaginatedResult;
  loading: boolean;
  error?: any;
  commonQueryVariables: CommonQueryVariables;
  filtersAvailable: boolean;
  chart: {
    chartData: LineChartDataPoint[];
    columnsConfig: ReportConfig[];
    averageScore: number | null;
    comparisonAverageScore: number | null;
    totalCalls: number;
    numInterviewers: number;
    loading: boolean;
    error?: any;
    bucketSize?: number;
    bucketInterval?: string;
  };
  refetchAllData: () => void;
};

/**
 * Query results and any error states related to quality report.
 */
const useReportData = (reportConfig: AnalyticsConfig): ReportData => {
  const trendsEnabled = useFeatureFlag("analytics:quality-report-trends");

  const commonQueryVariables: CommonQueryVariables = {
    metric: MetricName.Report,
    dateRangeStart: formatISODate(reportConfig.dateRange.value.start),
    dateRangeEnd: formatISODate(reportConfig.dateRange.value.end),
    ...reportConfig.filters,
  };

  const reportDataQuery = useAnalyticsReportQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...commonQueryVariables,
      primaryDimension: reportConfig.primaryDimension.value,
      secondaryDimension: reportConfig.secondaryDimension.value,
    },
  });
  const dataPoints = reportDataQuery.data?.qualityReport?.data || [];
  const sortedDataPoints = sortTableData(
    dataPoints,
    reportConfig.report.sortColumn.value,
    reportConfig.report.sortDirection.value
  );

  let pageLimit = reportConfig.chartLimit.value;
  if (pageLimit === -1) {
    pageLimit = dataPoints.length;
  }

  const reportTrendsDataQuery = useAnalyticsReportTrendsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...commonQueryVariables,
    },
    skip: !trendsEnabled,
  });

  const baseData = reportTrendsDataQuery.data?.qualityReportTrends?.data || [];
  const comparisonData =
    reportTrendsDataQuery.data?.qualityReportTrends?.comparisonData || [];
  const columnsConfig =
    reportTrendsDataQuery.data?.qualityReportTrends?.config || [];
  const { bucketSize, bucketInterval } =
    reportTrendsDataQuery.data?.qualityReportTrends || {};

  const interviewersQuery = useAnalyticsReportQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...commonQueryVariables,
      primaryDimension: AnalyticsDimension.Interviewer,
      secondaryDimension: AnalyticsDimension.None,
    },
  });
  const numInterviewers =
    interviewersQuery.data?.qualityReport?.data?.length || 0;

  const refetchAllData = (): void => {
    reportDataQuery.refetch();
    reportTrendsDataQuery.refetch();
    interviewersQuery.refetch();
  };

  return {
    report: reportDataQuery.data?.qualityReport,
    pagination: paginate(
      sortedDataPoints,
      reportConfig.report.page.value,
      pageLimit
    ),
    loading: reportDataQuery.loading,
    error: reportDataQuery.error,
    commonQueryVariables,
    filtersAvailable: true,
    chart: {
      chartData: getLineChartData(baseData, comparisonData),
      columnsConfig,
      averageScore: getAverageScore(baseData),
      comparisonAverageScore: getAverageScore(comparisonData),
      totalCalls: baseData.reduce((acc: number, d) => {
        const calls: number = d.totalCalls || 0;
        return acc + calls;
      }, 0),
      numInterviewers,
      bucketSize: bucketSize || 0,
      bucketInterval: bucketInterval || "",
      loading: reportTrendsDataQuery.loading || interviewersQuery.loading,
      error: reportTrendsDataQuery.error || interviewersQuery.error,
    },
    refetchAllData,
  };
};

export default useReportData;
