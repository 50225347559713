import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

import { Alert, LoadingIndicator, useToast } from "../../../components";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { usePageTracker, useSendGAEvent } from "../../../utils/googleAnalytics";
import { GuideItemType, useCallGuideQuery } from "../../graphql";
import useUpdateCallGuide from "../../graphql/hooks/CallGuide/useUpdateCallGuide";
import DefaultLayout from "../../layouts/DefaultLayout";
import Forbidden from "../forbidden/Forbidden";
import AssignedUsers from "./AssignedUsers";
import GuideHeader from "./GuideHeader";
import GuideItemList from "./GuideItemList";
import InterviewScoringToggle from "./InterviewScoringToggle";
import { CallGuide } from "./types";
import { stripTypenameFromCues } from "./util";

const GuidePage: React.FC = () => {
  usePageTracker("call_guide");
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState(0);
  const sendGAEvent = useSendGAEvent();
  const [isEditing, setIsEditing] = useState(false);
  const [guide, setGuide] = useState<CallGuide | null>(null);
  const [idsWithErrors, setIdsWithErrors] = useState<string[]>([]);
  const [haveChangesBeenMade, setHaveChangesBeenMade] = useState(false);
  const [updateCallGuide] = useUpdateCallGuide({
    onError: (err) => {
      toast({
        status: "error",
        description: err.message || "Error updating guide",
      });
      const idsWithErrors: string[] = [];
      guide?.cues.forEach((item) => {
        if (item.type === GuideItemType.Competency) {
          if (!item.competencyId) {
            idsWithErrors.push(item.id);
          }
          item.childItems.forEach((childItem) => {
            if (!childItem.description) {
              idsWithErrors.push(childItem.id);
            }
          });
        } else if (!item.description) {
          idsWithErrors.push(item.id);
        }
      });
      setIdsWithErrors(idsWithErrors);
    },
    onCompleted: () => {
      toast({ status: "success", description: "Guide updated successfully" });
      setIsEditing(false);
      setIdsWithErrors([]);
    },
  });
  const { percentHeight, layoutHeight } = useWindowDimensions();
  const { callGuideId } = useParams() as { callGuideId: string };

  const { data, loading, error } = useCallGuideQuery({
    variables: { id: callGuideId },
  });

  const [originalGuide, setOriginalGuide] = useState<CallGuide | null>(null);
  useEffect(() => {
    if (data) {
      setGuide(data?.callGuide as CallGuide);
      setOriginalGuide(data?.callGuide as CallGuide);
      if (!data.callGuide?.cues.length) {
        setIsEditing(true);
      }
    }
  }, [data]);

  if (loading) {
    return (
      <DefaultLayout>
        <LoadingIndicator mt={percentHeight(25)} />
      </DefaultLayout>
    );
  }

  if (error) {
    if (error.graphQLErrors.some((e) => e.extensions?.code === "FORBIDDEN")) {
      return (
        <Forbidden forbiddenFrom="guide">
          You can request access from the Hiring Team Admin.
        </Forbidden>
      );
    }
    return <Alert status="error" description="Error loading guide" reload />;
  }

  if (!data?.callGuide) {
    return <Navigate to="/not-found" />;
  }

  const onSave = (): void => {
    if (guide) {
      sendGAEvent("update", "call_guides");
      updateCallGuide({
        variables: {
          ...guide,
          name: guide.name || "",
          positionId: guide.position?.id || "",
          guideItems: stripTypenameFromCues(guide.cues),
        },
      });
    }
  };

  const onCancel = (): void => {
    setGuide(originalGuide);
    setIsEditing(false);
    setIdsWithErrors([]);
  };

  // Treat any guides created in the ATS as read-only.
  const readOnly = !guide?.canEdit || !!guide.atsId;
  const assignedCount = guide ? guide.assignedUsers.length : 0;

  return (
    <DefaultLayout>
      <Flex minHeight={layoutHeight} direction="column">
        {guide && (
          <>
            <GuideHeader
              callGuide={guide}
              setGuide={setGuide}
              readOnly={readOnly}
              isEditing={isEditing}
              haveChangesBeenMade={haveChangesBeenMade}
              setIsEditing={setIsEditing}
              setHaveChangesBeenMade={setHaveChangesBeenMade}
              shouldHideEditingButton={tabIndex !== 0}
              onSave={onSave}
              onCancel={onCancel}
            />
            <Tabs
              isLazy
              flex="1"
              bg={tabIndex === 0 ? "gray.50" : "white"}
              onChange={(index: number): void => {
                setTabIndex(index);
              }}
            >
              <TabList bg="white" pl="8">
                <Tab>Guide content</Tab>
                {isEditing ? (
                  <Tooltip
                    label="Save changes to go to the Assigned interviewers tab"
                    shouldWrapChildren
                  >
                    <Tab isDisabled>
                      Assigned interviewers{" "}
                      {assignedCount > 0 ? `(${assignedCount})` : ""}
                    </Tab>
                  </Tooltip>
                ) : (
                  <Tab>
                    Assigned interviewers{" "}
                    {assignedCount > 0 ? `(${assignedCount})` : ""}
                  </Tab>
                )}
              </TabList>

              <TabPanels pt={4}>
                <TabPanel>
                  <Flex alignItems="center" direction="column" pb={12}>
                    {guide.organization.scoringEnabled && (
                      <Box mt={4} width="80%">
                        <InterviewScoringToggle
                          callGuide={guide}
                          isEditing={isEditing}
                          setGuide={setGuide}
                        />
                      </Box>
                    )}
                    <GuideItemList
                      callGuide={guide}
                      readOnly={readOnly}
                      isEditing={isEditing}
                      setGuide={setGuide}
                      setHaveChangesBeenMade={setHaveChangesBeenMade}
                      idsWithErrors={idsWithErrors}
                    />
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <AssignedUsers callGuide={guide} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </Flex>
    </DefaultLayout>
  );
};

export default GuidePage;
