import {
  Box,
  IconButton,
  Link,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import {
  HiOutlineChevronDown,
  HiOutlineChevronUp,
  HiOutlineInformationCircle,
} from "react-icons/hi2";

import { AnalyticsDimension } from "../../graphql";
import { REPORT_METRIC_MAP } from "./const";
import { AnalyticsConfig, ReportColumn, ReportSortDirection } from "./types";
import useAnalyticsContext from "./useAnalyticsContext";
import { ReportData } from "./useReportData";
import { isMetricSupportedForOrg } from "./utils";

const TABLE_CELL_PROPS: TableCellProps = {
  px: 2,
  minW: "100px",
  textAlign: "center",
};

const TABLE_HEADER_CELL_PROPS = {
  ...TABLE_CELL_PROPS,
  fontSize: "xs",
};
const TABLE_DATA_CELL_PROPS = {
  ...TABLE_CELL_PROPS,
  fontSize: "sm",
};

type AnalyticsReportTableProps = {
  reportData: ReportData;
  reportConfig: AnalyticsConfig;
  linkToMyInsights?: boolean;
};

const AnalyticsReportTable: React.FC<AnalyticsReportTableProps> = ({
  reportData,
  reportConfig,
  linkToMyInsights = false,
}) => {
  const { atsDataState } = useAnalyticsContext();
  // Report config is used to manage the UI, filters, and dimensions
  // Table config is the user-defined columns to use for scoring
  const tableConfig = reportData.report?.config;
  const primaryDimensionLabel = reportConfig.primaryDimension.options.find(
    (o) => o.value === reportConfig.primaryDimension.value
  )?.label;
  const SORT_CONTROLS = reportConfig.report;

  const includeInterviewerLink =
    linkToMyInsights &&
    reportConfig.primaryDimension.value === AnalyticsDimension.Interviewer;

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <ThSortable
              paddingLeft="4"
              textAlign="left"
              dataKey="xLabel"
              {...SORT_CONTROLS}
            >
              {primaryDimensionLabel}
            </ThSortable>
            <ThSortable dataKey="totalCalls" {...SORT_CONTROLS}>
              Interview
              <br />
              count
            </ThSortable>
            <ThSortable
              dataKey="score"
              tooltipText="This score is an average of the individual metric scores, given the selected filters."
              {...SORT_CONTROLS}
            >
              Overall
              <br />
              interview quality
              <br />
              score
            </ThSortable>
            {tableConfig?.map((c) => {
              if (
                !isMetricSupportedForOrg(
                  REPORT_METRIC_MAP[c.name].metric,
                  atsDataState
                )
              ) {
                return null;
              }
              return (
                <ThSortable
                  key={c.name}
                  dataKey={REPORT_METRIC_MAP[c.name].dataKey}
                  tooltipText={REPORT_METRIC_MAP[c.name].description}
                  {...SORT_CONTROLS}
                >
                  {REPORT_METRIC_MAP[c.name].tableHeaderNode}
                </ThSortable>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {reportData.pagination.currentPageItems.map((row) => (
            <Tr key={row.id}>
              <TdWhite textAlign="left" paddingLeft="4">
                <XLabel
                  interviewerIds={row.interviewerIds}
                  includeInterviewerLink={includeInterviewerLink}
                >
                  {row.xLabel}
                </XLabel>
              </TdWhite>
              <TdWhite>{row.totalCalls}</TdWhite>
              <TdScore score={row.score}>{row.score}</TdScore>
              {tableConfig?.map((c) => {
                const metric = REPORT_METRIC_MAP[c.name];
                if (!isMetricSupportedForOrg(metric.metric, atsDataState)) {
                  return null;
                }
                return (
                  <TdWhite key={c.name}>
                    {(function F(c) {
                      const key = metric.dataKey;
                      const val = row[key];
                      if (typeof val === "number") {
                        return Math.round((row[key] as number) * 100) / 100;
                      }
                      return (
                        <Tooltip label="No data available.">
                          <Box as="span" color="gray.400">
                            —
                          </Box>
                        </Tooltip>
                      );
                    })(c)}
                  </TdWhite>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

// TODO: Handle case where there are multiple interviewer IDs for row
const XLabel: React.FC<{
  children: React.ReactNode;
  interviewerIds: string[];
  includeInterviewerLink: boolean;
}> = ({ children, interviewerIds, includeInterviewerLink }) =>
  includeInterviewerLink && interviewerIds.length === 1 ? (
    <Link fontWeight="500" href={`/insights/interviewer/${interviewerIds[0]}`}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

export const ThGray: React.FC<
  {
    children: React.ReactNode;
  } & TableColumnHeaderProps
> = ({ children, ...props }) => (
  <Th
    bg="gray.50"
    fontWeight="500"
    textTransform="none"
    whiteSpace="nowrap"
    {...TABLE_HEADER_CELL_PROPS}
    {...props}
  >
    {children}
  </Th>
);

const ThSortable: React.FC<
  {
    dataKey: ReportColumn;
    sortColumn: AnalyticsConfig["report"]["sortColumn"];
    sortDirection: AnalyticsConfig["report"]["sortDirection"];
    children: React.ReactNode;
    tooltipText?: string;
  } & TableCellProps
> = ({
  dataKey,
  sortColumn,
  sortDirection,
  children,
  tooltipText,
  ...props
}) => (
  <ThGray
    cursor="pointer"
    onClick={() => {
      if (sortColumn.value === dataKey) {
        sortDirection.setValue(sortDirection.value === "asc" ? "desc" : "asc");
        return;
      }
      sortColumn.setValue(dataKey);
      sortDirection.setValue("desc");
    }}
    {...TABLE_HEADER_CELL_PROPS}
    {...props}
  >
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        {children}
        {tooltipText && (
          <Tooltip
            label={tooltipText}
            aria-label="Report column description tooltip"
          >
            <IconButton
              aria-label="Report column description button"
              variant="unstyled"
              size="xxs"
              ml="1"
              color="gray.800"
              mb="1px"
              icon={<HiOutlineInformationCircle />}
            />
          </Tooltip>
        )}
      </Box>
      <SortIcon
        key={`${dataKey}${sortColumn.value}`}
        sortDirection={sortDirection.value}
        isActive={sortColumn.value === dataKey}
      />
    </Box>
  </ThGray>
);

const SortIcon: React.FC<{
  sortDirection: ReportSortDirection;
  isActive: boolean;
}> = ({ isActive, sortDirection }) => {
  const color = isActive ? "gray.500" : "white";
  const icon =
    sortDirection === "asc" ? (
      <HiOutlineChevronUp color={color} />
    ) : (
      <HiOutlineChevronDown color={color} />
    );
  return <Box ml={isActive ? 1.5 : 0}>{icon}</Box>;
};

const TdScore: React.FC<{ score: number; children: React.ReactNode }> = ({
  score,
  children,
}) => {
  const colorProps =
    score >= 80
      ? { bg: "green.50", color: "green.800" }
      : score >= 61
      ? { bg: "yellow.50", color: "yellow.800" }
      : { bg: "red.50", color: "red.800" };
  return (
    <Td fontWeight="600" {...colorProps} {...TABLE_DATA_CELL_PROPS}>
      {children}
    </Td>
  );
};

export const TdWhite: React.FC<
  { children: React.ReactNode } & TableCellProps
> = ({ children, ...props }) => (
  <Td {...TABLE_DATA_CELL_PROPS} {...props}>
    {children}
  </Td>
);

export default AnalyticsReportTable;
