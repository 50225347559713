export type SegmentName =
  | "all"
  | "M"
  | "F"
  | "U"
  | "ACTIVE"
  | "REJECTED"
  | "HIRED";

export const segmentLabels: { [key in SegmentName]: string } = {
  all: "All",
  // Gender segmentation
  M: "Inferred Male",
  F: "Inferred Female",
  U: "Unknown Gender",
  // Application status segmentation
  ACTIVE: "Active",
  REJECTED: "Rejected",
  HIRED: "Hired",
};

// Color does not fit within existing colors scales but is needed for clarity.
// This should be moved into src/theme/colors.ts once approved for general use.
export const dataVizColorRanges = {
  hipsterSalmon: {
    100: "#FFE5E2",
    200: "##FECCC5",
    300: "#FEB2A9",
    400: "#FD998C",
    500: "#FD7F6F",
    600: "#DD6F61",
    700: "#BE5F53",
    800: "#9E4F45",
    900: "#7F4038",
  },
  meadowBlossomBlue: {
    100: "#E5EFF7",
    200: "#CBDFEE",
    300: "#B2D0E6",
    400: "#98C0DD",
    500: "#7EB0D5",
    600: "#6E9ABA",
    700: "#5F84A0",
    800: "#4F6E85",
    900: "#3F586B",
  },
  lastOfLettuce: {
    100: "#F0F9DF",
    200: "#E0F3C0",
    300: "#D1ECA0",
    400: "#C1E681",
    500: "#B2E061",
    600: "#9CC455",
    700: "#86A849",
    800: "#6F8C3D",
    900: "#597031",
  },
  lavenderSweater: {
    100: "#F2E5F2",
    200: "#E5CBE5",
    300: "#D7B2D8",
    400: "#CA98CB",
    500: "#BD7EBE",
    600: "#A56EA6",
    700: "#8E5F8F",
    800: "#764F77",
    900: "#5F3F5F",
  },
  goldenRainYellow: {
    100: "#FFF0DE",
    200: "#FFE1BD",
    300: "#FFD39C",
    400: "#FFC47B",
    500: "#FFB55A",
    600: "#DF9E4F",
    700: "#BF8844",
    800: "#9F7138",
    900: "#805B2D",
  },
  caduceusGold: {
    100: "#FFFCE0",
    200: "#FFF8C1",
    300: "#FFF5A3",
    400: "#FFF184",
    500: "#FFEE65",
    600: "#DFD058",
    700: "#BFB34C",
    800: "#9F953F",
    900: "#807733",
  },
  beatrice: {
    100: "#F2F1F8",
    200: "#E5E3F1",
    300: "#D8D5E9",
    400: "#CBC7E2",
    500: "#BEB9DB",
    600: "#A6A2C0",
    700: "#8F8BA4",
    800: "#777489",
    900: "#5F5D6E",
  },
  classicRose: {
    100: "#FFF5FA",
    200: "#FEEBF5",
    300: "#FEE0EF",
    400: "#FDD6EA",
    500: "#FDCCE5",
    600: "#DDB3C8",
    700: "#BE99AC",
    800: "#9E808F",
    900: "#7F6673",
  },
  greenDaze: {
    100: "#E8F6F4",
    200: "#D1EDE9",
    300: "#B9E5DD",
    400: "#A2DCD2",
    500: "#8BD3C7",
    600: "#7AB9AE",
    700: "#689E95",
    800: "#57847C",
    900: "#466A64",
  },
};

export const dataVizColorRangesBright = {
  lottiRed: {
    100: "#FACCDB",
    200: "#F599B6",
    300: "#F06692",
    400: "#EB336D",
    500: "#E60049",
    600: "#C90040",
    700: "#AD0037",
    800: "#90002E",
    900: "#730025",
  },
  blueBolt: {
    100: "#CEF0FF",
    200: "#9DE1FF",
    300: "#6DD2FF",
    400: "#3CC3FF",
    500: "#0BB4FF",
    600: "#0A9EDF",
    700: "#0887BF",
    800: "#07719F",
    900: "#065A80",
  },
  thalliumFlame: {
    100: "#DCFBE9",
    200: "#B9F6D3",
    300: "#96F2BD",
    400: "#73EDA7",
    500: "#50E991",
    600: "#46CC7F",
    700: "#3CAF6D",
    800: "#32925B",
    900: "#287549",
  },
  bananaClan: {
    100: "#FAF7CC",
    200: "#F5EF99",
    300: "#F0E866",
    400: "#EBE033",
    500: "#E6D800",
    600: "#C9BD00",
    700: "#ADA200",
    800: "#908700",
    900: "#736C00",
  },
  singaporeOrchid: {
    100: "#EBD1FD",
    200: "#D7A3FB",
    300: "#C375F9",
    400: "#AF47F7",
    500: "#9B19F5",
    600: "#8816D6",
    700: "#7413B8",
    800: "#611099",
    900: "#4E0D7B",
  },
  picoOrange: {
    100: "#FFEDCC",
    200: "#FFDA99",
    300: "#FFC866",
    400: "#FFB533",
    500: "#FFA300",
    600: "#DF8F00",
    700: "#BF7A00",
    800: "#9F6600",
    900: "#805200",
  },
  aphroditeanFuchsia: {
    100: "#F8CEF0",
    200: "#E5E3F1",
    300: "#EA6CD2",
    400: "#E33BC3",
    500: "#DC0AB4",
    600: "#C1099E",
    700: "#A50887",
    800: "#8A0671",
    900: "#6E055A",
  },
  iceColdStare: {
    100: "#F0F6FF",
    200: "#E1EEFF",
    300: "#D1E5FF",
    400: "#C2DDFF",
    500: "#B3D4FF",
    600: "#9DBADF",
    700: "#869FBF",
    800: "#70859F",
    900: "#5A6A80",
  },
  aareRiver: {
    100: "#CCF2EC",
    200: "#99E5D9",
    300: "#66D9C6",
    400: "#33CCB3",
    500: "#00BFA0",
    600: "#00A78C",
    700: "#008F78",
    800: "#007764",
    900: "#006050",
  },
};

// TODO: Pick colors for application status segmentation !!

export const themedSegmentColors = (
  colors: any
): { [key in SegmentName]: string } => ({
  all: dataVizColorRanges.beatrice["600"],
  // Gender segmentation
  M: dataVizColorRanges.lavenderSweater["600"],
  F: dataVizColorRanges.goldenRainYellow["600"],
  U: dataVizColorRanges.greenDaze["600"],
  // Application status segmentation
  ACTIVE: dataVizColorRangesBright.thalliumFlame["600"],
  REJECTED: dataVizColorRangesBright.picoOrange["600"],
  HIRED: dataVizColorRangesBright.blueBolt["600"],
});

export const themedSegmentColorsFocused = (
  colors: any
): { [key in SegmentName]: string } => ({
  all: dataVizColorRanges.beatrice["400"],
  // Gender segmentation
  M: dataVizColorRanges.lavenderSweater["400"],
  F: dataVizColorRanges.goldenRainYellow["400"],
  U: dataVizColorRanges.greenDaze["400"],
  // Application status segmentation
  ACTIVE: dataVizColorRangesBright.thalliumFlame["400"],
  REJECTED: dataVizColorRangesBright.picoOrange["400"],
  HIRED: dataVizColorRangesBright.blueBolt["400"],
});
