import { Box, Flex, Spacer } from "@chakra-ui/react";
import React, { useState } from "react";
import { BsDot } from "react-icons/bs";

import { useSendGAEvent } from "../../../utils/googleAnalytics";
import { CandidateAlertFeedFilter } from "../../graphql";
import useCurrentUser from "../../hooks/useCurrentUser";
import ActivityFeed from "../../pages/home/ActivityFeed/ActivityFeed";
import CandidateAlertFeed from "../CandidateAlert/CandidateAlertFeed";
import {
  CandidateAlertFilters,
  TypeFilter,
} from "../CandidateAlert/CandidateAlertFilters";
import { CandidateAlertSettings } from "../CandidateAlert/CandidateAlertSettings";
import { MarkAllAsRead } from "../CandidateAlert/MarkAllAsRead";
import { useCandidateAlertDisabled } from "../CandidateAlert/useCandidateAlertDisabled";
import { SidebarTab, SidebarTabs } from "../Recording";

enum FeedSidebarTab {
  ACTIVITY = "activity",
  ALERTS = "alerts",
}

const FeedSidebar: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(FeedSidebarTab.ALERTS);
  const [markAllReadClickCount, setMarkAllReadClickCount] = useState(0);
  const [hasNoAlerts, setHasNoAlerts] = useState(false);
  const sendGAEvent = useSendGAEvent();
  const currentUser = useCurrentUser();
  const hasUnreadAlerts = currentUser.numUnreadAlerts > 0;
  const [availableFilters, setAvailableFilters] = useState<TypeFilter[]>([
    TypeFilter.Interviews,
    TypeFilter.Type,
  ]);

  const [currentFilters, setCurrentFilters] = useState([
    CandidateAlertFeedFilter.All,
  ]);

  const candidateAlertsDisabled = useCandidateAlertDisabled();

  const updateFilterActionItems = (show: boolean): void => {
    setCurrentFilters((prevFilters) => {
      if (show) {
        // Clears all of the filters for type/category to re-show action items.
        return prevFilters.filter((item) => {
          if (
            item === CandidateAlertFeedFilter.ClosingRisk ||
            item === CandidateAlertFeedFilter.ActionItem
          ) {
            return false;
          }
          return true;
        });
      }
      return prevFilters.filter((item) => {
        if (item === CandidateAlertFeedFilter.ActionItem) {
          return false;
        }
        return true;
      });
    });
    // Needed to hide the Type Filter UI
    setAvailableFilters((prevFilters) => {
      if (show) {
        if (!availableFilters.includes(TypeFilter.Type)) {
          return [...prevFilters, TypeFilter.Type];
        }
        return [...prevFilters];
      }
      return prevFilters.filter((item) => {
        if (item === TypeFilter.Type) {
          return false;
        }
        return true;
      });
    });
  };

  if (candidateAlertsDisabled) {
    return (
      <Box
        border="1px solid"
        borderColor="gray.100"
        borderRadius="lg"
        color="gray.900"
        bg="white"
        width="100%"
      >
        <ActivityFeed header="Recent activity" />
      </Box>
    );
  }

  return (
    <Box
      border="1px solid"
      borderColor="gray.100"
      borderRadius="lg"
      color="gray.900"
      bg="white"
      width="100%"
    >
      <SidebarTabs
        id="homepage-feed-sidebar"
        currentTab={currentTab}
        borderBottom="none"
        onTabChange={(newTab) => {
          if (newTab !== currentTab) {
            sendGAEvent("feed_sidebar_tab_change", "call_review", newTab);
          }
          setCurrentTab(newTab);
        }}
        tabListWrapperProps={{
          borderBottom: "none",
          height: "auto",
          pt: "4",
          pb: "3",
        }}
      >
        <SidebarTab
          id={FeedSidebarTab.ALERTS}
          displayName={
            <Flex alignItems="center">
              Alerts
              {hasUnreadAlerts && (
                <BsDot viewBox="4 4 12 12" size={20} color="red" />
              )}
            </Flex>
          }
        >
          <Flex
            direction="column"
            pb="4"
            data-testid="homepage-module-title-alerts"
          >
            <Box px="4" pb="4" borderBottomWidth="1px" borderColor="gray.100">
              <Flex mb="3">
                <CandidateAlertFilters
                  filters={availableFilters}
                  filterState={currentFilters}
                  onChange={setCurrentFilters}
                  spacer={<Spacer minW="2" maxW="5" />}
                />
              </Flex>

              <Flex justifyContent="space-between">
                <MarkAllAsRead
                  onClick={() =>
                    setMarkAllReadClickCount(markAllReadClickCount + 1)
                  }
                  disabled={hasNoAlerts}
                />
                <CandidateAlertSettings
                  updateFilterActionItems={updateFilterActionItems}
                />
              </Flex>
            </Box>
            <CandidateAlertFeed
              markAllReadClickCount={markAllReadClickCount}
              alertFilters={currentFilters}
              onAlertsFetched={(alerts) => setHasNoAlerts(alerts.length === 0)}
            />
          </Flex>
        </SidebarTab>
        <SidebarTab id={FeedSidebarTab.ACTIVITY} displayName="Recent activity">
          <ActivityFeed />
        </SidebarTab>
      </SidebarTabs>
    </Box>
  );
};

export default FeedSidebar;
