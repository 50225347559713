import { Box, Flex, Icon, Link } from "@chakra-ui/react";
import React from "react";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";

import { formatRelativeDate } from "../../../utils/datetime";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CandidateAlertListItemFragment,
  CandidateAlertType,
} from "../../graphql";

interface CandidateAlertCandidateListItemProps
  extends CandidateAlertListItemFragment {
  isLast: boolean;
  isFirst: boolean;
}

export const CandidateAlertCandidateListItem: React.FC<
  CandidateAlertCandidateListItemProps
> = ({ isLast, call, text, startTime, candidate, type }) => {
  const sendGAEvent = useSendGAEvent();
  const callId = call.id;
  const candidateId = candidate.id;
  const interviewTimestamp = call.startTime;
  return (
    <Box
      padding="12px 16px 16px 16px"
      borderBottom={isLast ? "0px" : "1px"}
      borderColor="gray.100"
    >
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <Box fontSize="sm" fontWeight="600">
          {type === CandidateAlertType.ClosingRisk
            ? "Closing risk"
            : "Action item"}
        </Box>
        <Box fontSize="xs" fontWeight="400" color="gray.600">
          {formatRelativeDate(interviewTimestamp)}
        </Box>
      </Flex>
      <Box>
        <Box
          border="1px"
          borderColor="gray.100"
          borderRadius="6px"
          backgroundColor="gray.50"
          padding="10px"
        >
          <Flex direction="column">
            <Box fontSize="sm" fontWeight="400" color="gray.700">
              {text}
            </Box>
            <Link
              pt="1"
              href={`/interview/${callId}?t=${startTime}`}
              fontWeight="500"
              color="blue.600"
              fontSize="xs"
              target="_blank"
              onClick={() => {
                sendGAEvent(
                  "open_interview",
                  "candidate_alert",
                  undefined,
                  undefined,
                  {
                    callId,
                    candidateId,
                  }
                );
              }}
            >
              Open Interview
              <Icon as={HiArrowTopRightOnSquare} ml="1" size={32} />
            </Link>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};
