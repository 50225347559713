import { Icon, Link } from "@chakra-ui/react";
import React from "react";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

type MarkAllAsReadProps = {
  disabled: boolean;
  onClick(): void;
};

export const MarkAllAsRead: React.FC<MarkAllAsReadProps> = ({
  disabled,
  onClick,
}) => {
  return (
    <Link
      fontSize="xs"
      onClick={onClick}
      pointerEvents={disabled ? "none" : undefined}
      display="flex"
      alignItems="center"
      gap="1"
      lineHeight="4"
      color={disabled ? "gray.200" : undefined}
    >
      <Icon
        as={IoCheckmarkDoneSharp}
        boxSize="4"
        color={disabled ? "gray.200" : "blue.600"}
      />
      Mark all as read
    </Link>
  );
};
