import React, { useCallback } from "react";

import { SelectDateRangeState } from "../../../../components/SelectDateRange/SelectDateRange";
import { useSendGAEvent } from "../../../../utils/googleAnalytics";
import { AnalyticsDimension, MetricName } from "../../../graphql";
import AnalyticsReportHero from "../AnalyticsReportHero";
import { METRIC_ENFORCED_DOMAINS, METRIC_RECOMMENDED_VALUES } from "../const";
import AnalyticsLineChart from "../line-chart/AnalyticsLineChart";
import ClickableActiveDot from "../line-chart/ClickableActiveDot";
import { MetricConfig } from "../MetricConfig";
import { ReportMetric } from "../types";
import useComparisonLineToggle from "../useComparisonLineToggle";
import {
  formatAnalyticsDateRange,
  formatValue,
  getBucketDatesFromISO,
  getLineChartData,
  getValidDataForMetric,
  mapFiltersToAlgoliaURL,
} from "../utils";
import { MyAnalyticsConfig } from "./useMyAnalyticsConfig";
import { MyInsightsData } from "./useMyInsightsData";

type MyAnalyticsLineChartProps = {
  myInsightsData: MyInsightsData;
  metric: ReportMetric;
  dateRangeState: SelectDateRangeState;
  benchmarkRange?: {
    upperBound: number;
    lowerBound: number;
  };
  queryConfig: MyAnalyticsConfig;
  interviewerFullName: string;
};

const MyAnalyticsLineChart: React.FC<MyAnalyticsLineChartProps> = ({
  myInsightsData,
  metric,
  dateRangeState,
  benchmarkRange,
  queryConfig,
  interviewerFullName,
}) => {
  const sendGAEvent = useSendGAEvent();
  const { showMIComparisonLine, toggleMIComparisonLine } =
    useComparisonLineToggle();

  const validData = getValidDataForMetric(
    myInsightsData.baseData,
    metric.rawDataKey
  );

  const recommendedValue = METRIC_RECOMMENDED_VALUES[metric.metric];
  const domain = METRIC_ENFORCED_DOMAINS[metric.metric];
  const benchmarkFormatter = (value: number): string =>
    formatValue(value, MetricConfig[metric.metric].valueFormat);

  const handleDotClick = useCallback(
    (payload: { datum: any }): void => {
      const date = payload?.datum?.xLabel;
      if (!date) return;
      const dateRangeValue = getBucketDatesFromISO(
        date,
        myInsightsData.bucketInterval,
        myInsightsData.bucketSize
      );
      const url = mapFiltersToAlgoliaURL(
        {
          ...queryConfig,
          primaryDimension: { value: AnalyticsDimension.None },
          interviewers: {
            labels: [interviewerFullName],
          },
          dateRange: {
            value: dateRangeValue,
          },
          filters: {
            positions: [],
            departments: [],
            stages: [],
            ...queryConfig.filters,
          },
        },
        { label: null },
        MetricName.Report
      );
      window.open(url, "_blank");
      sendGAEvent("my_analytics_drilldown", "analytics", MetricName.Report);
    },
    [
      myInsightsData.bucketSize,
      myInsightsData.bucketInterval,
      queryConfig,
      interviewerFullName,
    ]
  );

  return validData.length > 0 ? (
    <AnalyticsLineChart
      data={getLineChartData(
        myInsightsData.baseData,
        myInsightsData.comparisonData || []
      )}
      rangeDisplayValues={formatAnalyticsDateRange(dateRangeState)}
      columnsConfig={myInsightsData.columnsConfig}
      bucketSize={myInsightsData.bucketSize}
      bucketInterval={myInsightsData.bucketInterval}
      dataKey={`datum.${metric.rawDataKey}`}
      compareDataKey={`compareDatum.${metric.rawDataKey}`}
      yAxis={{
        formatter: (value) =>
          metric.format
            ? `${metric.format(value)}${metric.units || ""}`
            : value.toString(),
        domain,
      }}
      line={
        typeof recommendedValue === "number"
          ? {
              label: "Recommended",
              value: recommendedValue,
            }
          : undefined
      }
      tooltipVersion="myInsights"
      tooltipFooterText="Click dot to view interviews"
      benchmarkRange={benchmarkRange}
      benchmarkFormatter={benchmarkFormatter}
      showComparisonLine={showMIComparisonLine}
      toggleComparisonLine={toggleMIComparisonLine}
      customActiveDot={
        interviewerFullName.length > 0
          ? (props: any) => (
              <ClickableActiveDot onClick={handleDotClick} {...props} />
            )
          : undefined
      }
      drawDot={validData.length === 1}
    />
  ) : (
    <AnalyticsReportHero image />
  );
};

export default MyAnalyticsLineChart;
