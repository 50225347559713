import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  ListItem,
  Spinner,
  Text,
  Tooltip,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import { HiOutlineSparkles } from "react-icons/hi2";

import { Avatar } from "../../../../components";
import BotIcon from "../../../../components/Icons/BotIcon";
import { CopyIcon } from "../../../../components/Icons/CopyIcon";
import { copy } from "../../../../utils/clipboard";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useAskChat, { AskChatRole } from "../../Ask/useAskChat";
import AskBetaTag from "./AskBetaTag";

export const CandidateAsk: React.FC<{
  candidateId: string;
  onClose: () => void;
}> = ({ candidateId, onClose }) => {
  const [userInput, setUserInput] = useState("");

  const {
    chat,
    addUserMessage,
    busy: chatBusy,
    reset,
  } = useAskChat(undefined, candidateId);

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleSumbit = (): void => {
    addUserMessage(userInput, userInput);
    setUserInput("");
  };

  useEffect(() => {
    const scroll = scrollRef.current;
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight;
    }
  }, [chat.messages[chat.messages.length - 1]]);

  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current && !chatBusy) {
      ref.current.focus();
    }
  }, [ref.current, chatBusy]);

  return (
    <Flex
      flexDir="column"
      height="100%"
      pb={20}
      width="450px"
      justifyContent="space-between"
      borderLeft="1px solid"
      borderColor="gray.200"
      padding={3}
    >
      <Flex flexDirection="row" pt={4} mb={4}>
        <Text fontWeight="600" mr={2} ml={1}>
          Assistant
        </Text>
        <Box mt="2px">
          <AskBetaTag />
        </Box>
        <IconButton
          variant="icon"
          aria-label="Close"
          size="sm"
          ml="auto"
          mr="0px"
          icon={<HiOutlineX strokeWidth="1.5px" size={20} />}
          onClick={() => {
            onClose();
          }}
        />
      </Flex>
      {!chatBusy && chat.messages.length < 1 && <StartingSplash />}
      <Box ref={scrollRef} mt="auto" overflow="auto">
        {chat.messages.map((message, i) => {
          if (message.role === AskChatRole.Assistant) {
            // eslint-disable-next-line react/no-array-index-key
            return <BotMessage key={i} text={message.userContent} />;
          }
          // eslint-disable-next-line react/no-array-index-key
          return <UserMessage key={i} text={message.userContent} />;
        })}
      </Box>
      <Flex
        flexDir="column"
        borderTop="1px solid"
        borderColor="gray.200"
        pt={3}
        mt={4}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSumbit();
          }}
        >
          <Input
            ref={ref}
            borderRadius="base"
            size="md"
            fontSize="sm"
            placeholder="Ask about the candidate's interviews..."
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            disabled={chatBusy}
            autoFocus
          />
        </form>
        <Flex mt={3} mb={1} justifyContent="space-between">
          <Button
            size="sm"
            onClick={() => {
              setUserInput("");
              reset();
            }}
            variant="outline"
            disabled={chatBusy}
            visibility={chat.messages.length < 1 ? "hidden" : "visible"}
          >
            Start over
          </Button>
          <Button
            size="sm"
            onClick={() => {
              handleSumbit();
            }}
            disabled={chatBusy}
          >
            Submit
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const BotMessage: React.FC<{
  text?: string;
}> = ({ text }) => {
  const toast = useToast();
  return (
    <Flex flexDir="row" alignItems="flex-start" mt="24px" mr={2}>
      <Flex
        textColor="gray.900"
        fontSize="sm"
        whiteSpace="pre-wrap"
        p={2}
        borderRadius="md"
        bg="#FAF5FF"
        mr={2}
      >
        <Flex flexDir="row" width="100%">
          <Box width={8} flexShrink="0">
            <BotIcon />
          </Box>
          {text && <Box pt="2px">{text && text}</Box>}
          {!text && (
            <Box pt="4px">
              <Spinner color="purple.300" size="sm" />
            </Box>
          )}
        </Flex>
      </Flex>
      <Box flexShrink="0" ml="auto">
        <Tooltip label="Copy to clipboard" placement="bottom-start">
          <IconButton
            aria-label="Copy response"
            icon={<CopyIcon fontSize="20px" />}
            variant="ghost"
            color="blue.600"
            onClick={() => {
              copy(text || "");
              toast({
                title: "Response copied to clipboard",
                status: "success",
              });
            }}
          />
        </Tooltip>
      </Box>
    </Flex>
  );
};

const UserMessage: React.FC<{
  text: string;
}> = ({ text }) => {
  const currentUser = useCurrentUser();
  return (
    <Flex
      mt="24px"
      ml={10}
      mr={2}
      textColor="gray.900"
      fontSize="sm"
      whiteSpace="pre-wrap"
      p={2}
      pl={3}
      borderRadius="md"
      bg="gray.50"
    >
      <Flex flexDir="row" width="100%">
        <Box pt="2px">{text}</Box>
        <Box pl={2} width={8} flexShrink="0" ml="auto">
          <Avatar user={currentUser} />
        </Box>
      </Flex>
    </Flex>
  );
};

const StartingSplash: React.FC = () => {
  return (
    <Flex
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
      mt={8}
    >
      <Icon
        as={HiOutlineSparkles}
        strokeWidth={1}
        color="purple.400"
        h="80px"
        w="80px"
      />
      <Box fontWeight="medium" color="gray.600" mt={4}>
        <Text>Ask me anything about this candidate:</Text>
        <UnorderedList>
          <ListItem>a question</ListItem>
          <ListItem>to write an email</ListItem>
          <ListItem>to provide a summary</ListItem>
        </UnorderedList>
      </Box>
    </Flex>
  );
};
