import { Button } from "@chakra-ui/react";
import React from "react";
import { MdEmail } from "react-icons/md";

import { useToast } from "../../../components";
import { UserFragment, useSendInviteMutation } from "../../graphql";

interface SendInvitationButtonProps {
  user: Pick<UserFragment, "id" | "invitedBy">;
}

const SendInvitationButton: React.FC<SendInvitationButtonProps> = ({
  user,
}) => {
  const toast = useToast();
  const [sendInvite, { loading: sendInviteLoading }] = useSendInviteMutation({
    onError: (err) => {
      toast({
        title: "Error",
        description: `Failed to send invitation: ${err.message}`,
        status: "error",
      });
    },
    onCompleted: (data) => {
      if (data.sendInvite) {
        toast({
          title: "Success",
          description: "Sent invitation",
          status: "success",
        });
      }
    },
  });
  return (
    <Button
      variant="outline"
      size="xs"
      leftIcon={<MdEmail />}
      isLoading={sendInviteLoading}
      aria-label="Resend Invite"
      onClick={() => {
        sendInvite({ variables: { id: user.id } });
      }}
    >
      {user.invitedBy ? "Resend" : "Send"}
    </Button>
  );
};

export default SendInvitationButton;
