import { Badge, Box, BoxProps, Flex, Icon, Link } from "@chakra-ui/react";
import React, { useState } from "react";
import { BsDot } from "react-icons/bs";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import { HiArrowTopRightOnSquare } from "react-icons/hi2";

import { formatRelativeDate } from "../../../utils/datetime";
import { useSendGAEvent } from "../../../utils/googleAnalytics";
import {
  CandidateAlertFeedListItemFragment,
  CandidateAlertType,
} from "../../graphql";
import { CandidateAlertFeedback } from "./CandidateAlertFeedback";

interface CandidateAlertProps extends CandidateAlertFeedListItemFragment {
  handleAlertRead: (ids: string[]) => void;
  styles?: BoxProps;
}

const formatAlertType = (type: CandidateAlertType): string => {
  switch (type) {
    case CandidateAlertType.ClosingRisk: {
      return "Closing risk";
    }
    case CandidateAlertType.FollowUp: {
      return "Action item";
    }
    default: {
      return type;
    }
  }
};

export const CandidateAlert: React.FC<CandidateAlertProps> = ({
  id,
  alert,
  readAt,
  accuracyFeedback,
  handleAlertRead,
  styles,
}) => {
  const [isTextOpen, setIsTextOpen] = useState(false);
  const candidateName = alert.candidate.fullName;
  const candidateId = alert.candidate.id;
  const callId = alert.call.id;
  const interviewTimestamp = alert.call.startTime;
  const positionName = alert.position?.title;
  const { text, startTime, type } = alert;
  const sendGAEvent = useSendGAEvent();
  const handleClick = (): void => {
    if (!readAt) {
      handleAlertRead([id]);
    }
    setIsTextOpen((prevState) => !prevState);
    sendGAEvent("feed_click", "candidate_alert", undefined, undefined, {
      callId,
      candidateId,
    });
  };
  return (
    <Box
      px="2"
      pt="2"
      pb="3"
      borderBottomWidth="1px"
      _last={{ borderBottomWidth: "0" }}
      borderColor="gray.100"
      {...styles}
    >
      <Box onClick={handleClick} cursor="pointer">
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center">
            <BsDot
              viewBox="2 2 12 12"
              size={20}
              color="red"
              visibility={readAt ? "hidden" : "visible"}
            />
            <Box fontSize="sm" fontWeight="600">
              {candidateName}
            </Box>
          </Flex>
          <Flex alignItems="center">
            <Box fontSize="xs" fontWeight="400" color="gray.600" pr="2">
              {formatRelativeDate(interviewTimestamp)}
            </Box>
            <Icon
              as={isTextOpen ? HiOutlineChevronUp : HiOutlineChevronDown}
              boxSize="5"
              color="gray.400"
            />
          </Flex>
        </Flex>
        <Box pl="5" fontSize="xs" fontWeight="500" color="gray.600">
          {positionName}
        </Box>
        <Box pl="5" pt="1">
          <Badge
            color={
              type === CandidateAlertType.ClosingRisk ? "red.600" : "yellow.700"
            }
            backgroundColor={
              type === CandidateAlertType.ClosingRisk ? "red.100" : "yellow.100"
            }
            textTransform="none"
            fontSize="11"
            py="2px"
            px="6px"
            borderRadius="3px"
          >
            {formatAlertType(type)}
          </Badge>
        </Box>
      </Box>
      {isTextOpen && (
        <Box pl="5" pt="3">
          <Box
            pl="4"
            pr="4"
            pb="4"
            pt="3"
            border="1px"
            borderColor="gray.100"
            borderRadius="6px"
          >
            <Flex direction="column">
              <Box fontSize="sm" fontWeight="400" color="gray.700">
                {text}
              </Box>
              <Link
                pt="1"
                href={`/interview/${callId}?t=${startTime}`}
                fontWeight="500"
                color="blue.600"
                fontSize="xs"
                target="_blank"
                onClick={() => {
                  sendGAEvent(
                    "open_interview",
                    "candidate_alert",
                    undefined,
                    undefined,
                    {
                      callId,
                      candidateId,
                    }
                  );
                }}
              >
                Open Interview
                <Icon as={HiArrowTopRightOnSquare} ml="1" size={32} />
              </Link>
              <Box pt="2">
                <CandidateAlertFeedback
                  alertFeedId={id}
                  accuracyFeedback={accuracyFeedback}
                />
              </Box>
            </Flex>
          </Box>
        </Box>
      )}
    </Box>
  );
};
