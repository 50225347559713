import {
  Box,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { LoadingIndicator } from "../../../components";
import {
  formatDateDurationLine,
  getOffsetDateAsISOString,
  monthDisplayMap,
  parseDate,
} from "../../../utils/datetime";
import {
  InterviewImportDecisionReason,
  useCurrentUserInterviewImportRuleQuery,
  useCurrentUserScheduledInterviewsQuery,
  UserInterviewImportRule,
  useUpdateMyInterviewRecordingOptionsMutation,
} from "../../graphql";
import SettingsPageContainer from "../../pages/settings/shared/SettingsPageContainer";

const PAGE_LIMIT = 15;

const getUserFriendlyExcludeText = (
  importReason: InterviewImportDecisionReason | null | undefined
): string => {
  let excludeText =
    "Organization wide import rules are preventing this event from being captured by BrightHire.";
  switch (importReason) {
    case InterviewImportDecisionReason.Exclude: {
      excludeText =
        "One of the interviewers is an excluded user for your organization.";
      break;
    }
    case InterviewImportDecisionReason.ExcludeStage: {
      excludeText = "The interview stage is excluded for your organization.";
      break;
    }
    case InterviewImportDecisionReason.OptOutCandidate: {
      excludeText = "Candidate opted-out from being recorded.";
      break;
    }
    case InterviewImportDecisionReason.OptOutInterviewer: {
      excludeText = "An interviewer opted-out from being recorded.";
      break;
    }
  }
  return excludeText;
};

const MyInterviewRecordingOption: React.FC = () => {
  const { data, loading } = useCurrentUserInterviewImportRuleQuery();
  const [importRule, setImportRule] = useState<string>();
  useEffect(() => {
    setImportRule(data?.currentUser?.interviewImportRule || "none");
  }, [data]);

  const [startDate] = useState<string>(getOffsetDateAsISOString({ hours: -1 }));
  const [endDate] = useState<string>(getOffsetDateAsISOString({ days: 3 }));
  const [isInterviewsLoading, setIsInterviewsLoading] =
    useState<boolean>(false);

  const {
    data: interviews,
    loading: interviewsLoading,
    refetch,
  } = useCurrentUserScheduledInterviewsQuery({
    variables: {
      start: startDate,
      end: endDate,
      positionId: null,
      pagination: {
        limit: PAGE_LIMIT,
      },
    },
    fetchPolicy: "cache-and-network",
  });
  useEffect(() => {
    setIsInterviewsLoading(interviewsLoading);
  }, [interviewsLoading]);

  const [updateOptions, { loading: updateLoading }] =
    useUpdateMyInterviewRecordingOptionsMutation({
      onCompleted: () => {
        setIsInterviewsLoading(true);
        setTimeout(refetch, 5000); // allows for the updates to be processed
      },
    });
  const handleSave = (): void => {
    let importRuleUpdate: undefined | string;
    if (importRule !== "none") {
      importRuleUpdate = importRule;
    }
    updateOptions({
      variables: {
        importRule: importRuleUpdate,
      },
    });
  };

  const isLoading = loading || updateLoading;
  return (
    <SettingsPageContainer
      heading="Automatic Recording Options"
      subHeading="Choose when BrightHire joins your ATS interviews. Which interviews do you want to automatically record?"
    >
      <Flex dir="row" gap={3}>
        <Box>
          <Box textColor="gray.600" fontWeight="600" fontSize="sm" mb="2">
            Please select and save your preference
          </Box>
          <Flex
            py="2"
            px="3"
            backgroundColor="gray.50"
            height="272px"
            minH="272px"
            width="319px"
            maxW="319px"
            borderRadius="8px"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Box width="100%">
              <RadioGroup
                onChange={setImportRule}
                value={importRule}
                mt="2"
                isDisabled={isLoading}
              >
                <Stack>
                  <Radio
                    size="md"
                    value={UserInterviewImportRule.AllMyInterviews}
                  >
                    <Text fontWeight="400" fontSize="sm">
                      Automatically record all of my personal ATS interviews
                    </Text>
                  </Radio>
                  <Radio
                    size="md"
                    value={UserInterviewImportRule.OnlyInterviewerMyInterviews}
                  >
                    <Text fontWeight="400" fontSize="sm">
                      Only automatically record my personal ATS interviews when
                      I am the only interviewer
                    </Text>
                  </Radio>
                  <Radio size="md" value="none">
                    <Text fontWeight="400" fontSize="sm">
                      Don&apos;t automatically record my personal ATS interviews
                    </Text>
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
            <Box>
              <Button size="sm" disabled={isLoading} onClick={handleSave}>
                Save
              </Button>
            </Box>
          </Flex>
        </Box>
        <Box>
          <Box textColor="gray.600" fontWeight="600" fontSize="sm" mb="2">
            Upcoming interviews BrightHire will join
          </Box>
          <Box
            py="2"
            px="3"
            border="1px"
            borderColor="gray.200"
            borderRadius="8px"
            height="272px"
            minH="272px"
            maxH="272px"
            overflowY="scroll"
            width="319px"
            maxW="319px"
          >
            {isInterviewsLoading && <LoadingIndicator />}
            {!isInterviewsLoading &&
              interviews?.currentUser?.scheduledInterviews?.results &&
              interviews?.currentUser?.scheduledInterviews?.results?.length <
                1 && (
                <Box textColor="gray.800" fontWeight="600">
                  No interviews
                </Box>
              )}
            {!isInterviewsLoading &&
              interviews?.currentUser?.scheduledInterviews.results?.map(
                (interview) => (
                  <Tooltip
                    label={
                      interview.isImported === true
                        ? "Event is set to be imported. Please note that only events with video conference links will be able to be captured automatically."
                        : interview.isExcluded === true
                        ? getUserFriendlyExcludeText(
                            interview.importDecisionReason
                          )
                        : "This event can be imported."
                    }
                    key={interview.id}
                  >
                    <Box
                      py="1"
                      px="2"
                      border="1px"
                      borderRadius="8px"
                      borderColor={
                        interview.isImported === true
                          ? "blue.500"
                          : interview.isExcluded === true
                          ? "red.500"
                          : "gray.200"
                      }
                      backgroundColor={
                        interview.isImported === true
                          ? "blue.50"
                          : interview.isExcluded === true
                          ? "red.50"
                          : "white"
                      }
                      width="100%"
                      textColor="gray.800"
                      mb="1"
                    >
                      <Box fontSize="xs" fontWeight="600">
                        {interview.name ||
                          (interview.ghJobStageName &&
                            interview.candidate?.fullName &&
                            `${interview.ghJobStageName} - ${interview.candidate?.fullName}`)}
                      </Box>
                      <Box fontSize="xs" fontWeight="400">
                        {
                          monthDisplayMap[
                            parseDate(interview.scheduledStart).getMonth()
                          ]
                        }{" "}
                        {parseDate(interview.scheduledStart).getDate()} {" • "}
                        {formatDateDurationLine(
                          interview.scheduledStart,
                          interview.scheduledEnd
                        )}
                      </Box>
                    </Box>
                  </Tooltip>
                )
              )}
          </Box>
        </Box>
      </Flex>
    </SettingsPageContainer>
  );
};

export default MyInterviewRecordingOption;
