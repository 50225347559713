import {
  Box,
  Flex,
  FlexProps,
  IconButton,
  Text,
  Tooltip,
  TooltipProps,
  useTheme,
} from "@chakra-ui/react";
import React from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";

type BenchmarkLabelProps = {
  formattedLowerBound: string;
  formattedUpperBound: string;
  label?: string;
  tooltipPlacement?: TooltipProps["placement"];
} & FlexProps;

export const BenchmarkLabel = React.forwardRef<
  HTMLDivElement,
  BenchmarkLabelProps
>(
  (
    {
      formattedLowerBound,
      formattedUpperBound,
      label = "Benchmark Range",
      tooltipPlacement = "top-end",
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const { colors, fontSizes } = theme;

    return (
      <Flex ref={ref} {...props}>
        <Text
          fontSize={fontSizes.xs}
          fontWeight={400}
          color={colors.gray[600]}
          whiteSpace="nowrap"
        >
          {label}
        </Text>
        <LabelTooltip
          label={
            <>
              Fifty percent of interviews fall within this benchmark range of{" "}
              {formattedLowerBound} to {formattedUpperBound} based on data from
              our customer base.
            </>
          }
          placement={tooltipPlacement}
        >
          <IconButton
            aria-label="Benchmark description"
            variant="unstyled"
            size="xxs"
            ml="1"
            color="gray.800"
            icon={<HiOutlineInformationCircle />}
          />
        </LabelTooltip>
      </Flex>
    );
  }
);

const LabelTooltip: React.FC<TooltipProps> = ({ children, ...props }) => (
  <Box lineHeight={0}>
    <Tooltip
      bg="white"
      p="2"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      color="gray.800"
      boxShadow="none"
      fontSize="xs"
      shouldWrapChildren
      {...props}
    >
      {children}
    </Tooltip>
  </Box>
);

export default BenchmarkLabel;
