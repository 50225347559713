import { Link, Text } from "@chakra-ui/react";
import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";

import { AsyncModal, useToast } from "../../../../components";
import { ClipFragment } from "../../../graphql";
import { ShareModal, useClipShareModal } from "../../ShareModal";
import { VideoDisplayMode } from "..";
import CreateClip from "./CreateClipBeta";
import { ClipRange } from "./types";

export interface ClipModalProps {
  callId: string;
  initialClipRange: ClipRange;
  onClose: () => void;
  onClipCreated?: () => void;
  mediaSrc: string;
  positionId?: string;
  videoDisplayMode: VideoDisplayMode;
}

const ClipModal: React.FC<ClipModalProps> = ({
  callId,
  initialClipRange,
  onClose,
  onClipCreated,
  mediaSrc,
  positionId,
  videoDisplayMode,
}) => {
  const toast = useToast();
  useEffect(() => LogRocket.track("clips-open-modal", { beta: true }), []);

  const [clipForShare, setClipForShare] = useState<ClipFragment | null>(null);
  const [showShare, setShowShare] = useState(false);
  const showCreate = !showShare;

  const showClipCreatedToast = (newClip: ClipFragment): void => {
    toast({
      title: "Success",
      description: (
        <Text>
          Clip created.{" "}
          <Link
            fontWeight="semibold"
            href={`/interview/${newClip.callId}/clip/${newClip.id}`}
          >
            View clip
          </Link>
        </Text>
      ),
      status: "success",
      duration: 5000,
    });
  };

  const onModalClose = (): void => {
    onClose();
    setClipForShare(null);
    setShowShare(false);
  };

  const onClip = (newClip: ClipFragment): void => {
    onClipCreated?.();
    onModalClose();
    showClipCreatedToast(newClip);
  };

  const onClipAndShare = (newClip: ClipFragment): void => {
    onClipCreated?.();
    setClipForShare(newClip);
    setShowShare(true);
  };

  const onClipAndClipAgain = (newClip: ClipFragment): void => {
    onClipCreated?.();
    showClipCreatedToast(newClip);
  };

  const { getClipShareData, loading, ...shareModalProps } = useClipShareModal({
    clip: clipForShare,
    onUpdateClip: setClipForShare,
    onClose: onModalClose,
  });

  useEffect(() => {
    if (showShare) {
      getClipShareData();
    }
  }, [showShare]);

  return (
    <AsyncModal
      isOpen
      onClose={onModalClose}
      closeOnOverlayClick={false}
      loading={loading}
      contentProps={{
        maxWidth: showShare ? undefined : 800,
        minHeight: "300px",
        maxHeight: "calc(100% - 4.5rem)",
        mt: "2.65rem",
        // The share modal is short enough to not overflow but needs to
        // pop out. The create modal has the opposite needs.
        overflow: showCreate ? "auto" : undefined,
        mb: "0",
      }}
    >
      {showCreate && (
        <CreateClip
          callId={callId}
          mediaSrc={mediaSrc}
          initialClipRange={initialClipRange}
          onClip={onClip}
          onClipAndShare={onClipAndShare}
          onClipAndClipAgain={onClipAndClipAgain}
          onClose={onModalClose}
          videoDisplayMode={videoDisplayMode}
        />
      )}
      {clipForShare && showShare && (
        <ShareModal
          callId={clipForShare.callId}
          clipId={clipForShare.id}
          onClose={onModalClose}
          positionId={positionId}
          {...shareModalProps}
        />
      )}
    </AsyncModal>
  );
};

export default ClipModal;
